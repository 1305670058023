import { nest } from 'recompose'
import merge from 'lodash/merge'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import { fieldApps, fieldTypes } from '@infotech/view-config'
import { mapissuesModule } from '@infotech/4sides-front-core'
import { fieldsConf as mapissuesFieldsConf, IssueLinkFormatter } from '@infotech/mapissues'
import { FILTER_TYPE_IN, textSearchFilters } from '@infotech/filters'
import { WithTitle } from '@infotech/base-components'
import {
  MyUserAgreementsCrudFilter,
  myUserAgreementsFilterValueToFilter,
  myUserAgreementsFilterParseValue,
} from '@infotech/agreement'

import { GridPointEnhancer, ViewPointEnhancer } from 'components/Point'

const { defaultModuleConfig } = mapissuesModule.config

const {
  point: { apps: pointApps },
} = mapissuesFieldsConf

const energyFieldsConfig = {
  createDate: {
    name: `Дата регистрации`,
    apps: {
      [fieldApps.COLUMN]: {
        name : `Дата регистрации`,
        width: 130,
      },
      [fieldApps.VIEW]: {
        title: `Дата регистрации`,
      },
      [fieldApps.FILTER]: {
        title         : `Дата регистрации`,
        usePresetDates: true,
      },
    },
  },
  updateDate: {
    apps: {
      [fieldApps.FILTER]: {
        usePresetDates: true,
      },
    },
  },
  assignedDateTo: {
    apps: {
      [fieldApps.FILTER]: {
        usePresetDates: true,
      },
    },
  },
  statusId: {
    apps: {
      [fieldApps.FILTER]: {
        paramFieldName: null,
        parseValue    : (params) => {
          const param = get(params, FILTER_TYPE_IN, [])

          return (isArray(param)
            ? parseInt(param, 10)
            : param.split(`,`).map((val) => parseInt(val, 10))
          )
        },
        valueToFilter: (value) => ({
          [FILTER_TYPE_IN]: `(${value.join(`,`)})`,
        }),
      },
    },
  },
  text: {
    apps: {
      [fieldApps.FILTER]: {
        parseValue   : textSearchFilters.parseValue,
        valueToFilter: textSearchFilters.valueToFilter,
      },
    },
  },
  issueLink: {
    apps: {
      [fieldApps.VIEW]: {
        component: nest(WithTitle, IssueLinkFormatter),
      },
    },
  },
  agreement: {
    type: fieldTypes.TEXT,
    name: `Согласование`,
    apps: {
      [fieldApps.FILTER]: {
        title         : `Согласование`,
        component     : MyUserAgreementsCrudFilter,
        paramFieldName: `id`,
        parseValue    : myUserAgreementsFilterParseValue,
        valueToFilter : myUserAgreementsFilterValueToFilter,
      },
    },
  },
  point: {
    apps: {
      [fieldApps.COLUMN]: {
        formatter: GridPointEnhancer(pointApps[fieldApps.COLUMN].formatter),
        width    : 350,
      },
      [fieldApps.VIEW]: {
        component: ViewPointEnhancer(pointApps[fieldApps.VIEW].component),
      },
      [fieldApps.FORM]: {
        component: pointApps[fieldApps.FORM].component,
        required : false,
      },
    },
  },
}

export const fieldsConf = merge(
  {},
  mapissuesFieldsConf,
  energyFieldsConfig,
)

export const fgupColumnsList = [
  `id`,
  `summary`,
  `statusId`,
  `typeId`,
  `text`,
  `customerNameString`,
  `createDate`,
  `files`,
  `point`,
]

export const fgupViewList = [
  `assignedUserId`,
  `statusId`,
  `id`,
  `createDate`,
  `assignedDateFrom`,
  `assignedDateTo`,
  `userId`,
  `point`,
  `summary`,
  `text`,
  `files`,
  `archived`,
]

export const defaultFilterList = [
  `typeId`,
  `statusId`,
  `createDate`,
  `assignedDateTo`,
  `text`,
  `id`,
  `assignedUserId`,
  `agreement`,
  `archived`,
]

export const getFormConfig = (fieldsList, fieldsConfig, metadata) => (
  defaultModuleConfig.getFormConfig(fieldsList, fieldsConf, metadata)
)

export const getFilterConfig = (fieldsList, fieldsConfig, metadata) => (
  defaultModuleConfig.routing.list.filter.getFilterConfig(fieldsList, fieldsConf, metadata)
)

export const getViewConfig = (viewList, fieldsConfig, metadata) => (
  defaultModuleConfig.getViewConfig(fgupViewList, fieldsConf, metadata)
)

export const getGridConfig = (columnsList, fieldsConfig, metadata) => (
  defaultModuleConfig.getGridConfig(columnsList || fgupColumnsList, fieldsConf, metadata)
)

export const getAllColumns = (columnsList, fieldsConfig, metadata) => (
  defaultModuleConfig.routing.list.getAllColumns(fgupColumnsList, fieldsConf, metadata)
)

export const getAllFilters = (filtersList, fieldsConfig, metadata) => (
  defaultModuleConfig.routing.list.filter.getAllFilters(filtersList, fieldsConf, metadata)
)
