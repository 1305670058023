import React from 'react'
import { connect } from 'react-redux'
import {
  compose, withHandlers, withState,
} from 'recompose'
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Button as ReactstrapButton,
} from 'reactstrap'

import { fieldApps } from '@infotech/view-config'
import { Button } from '@infotech/base-components'
import { PrintIcon } from '@infotech/uikit-icons'

import { fieldsConf, defaultViewList } from '../../fieldsConf'
import Map from '../Map'
import { columns as defectsFields } from '../Defects/Defects'
import { STREETPART_DEFECTS } from '../../reducer'

import './PrintButton.scss'

const fields = defaultViewList.map(fieldName => ({
  fieldName,
  fieldTitle: fieldsConf[fieldName].apps[fieldApps.VIEW].title,
  component: fieldsConf[fieldName].apps[fieldApps.VIEW].component,
}))

const enhance = compose(
  withState(`isOpen`, `setIsOpen`, false),
  withHandlers({
    modalToggle: ({ setIsOpen }) => () => setIsOpen((isOpen) => !isOpen),
  }),  
  connect((state, { data: { id } }) => ({
    defects: state[STREETPART_DEFECTS][id] || [],
  })),  
)

const print = () => window.print()

const PrintIssueButton = ({
  isOpen,
  modalToggle,
  data,
  defects,
}) => {
  return (
    <div className="print-issue">
      <Button
        className="mr-2 ml-2"
        onClick={modalToggle}
        title="Печать"
      >
        <PrintIcon className="uikit-icon uikit-icon--centered" />
      </Button>
      <Modal
        className="print-modal" isOpen={isOpen}
        toggle={modalToggle}
      >
        <ModalBody>
          <Row className="print-buttons">
            <Col className="print-header" />
            <Col className="text-right">
              <ReactstrapButton color="primary mr-2" onClick={print}>Печать</ReactstrapButton>
              <ReactstrapButton color="secondary" onClick={modalToggle}>Oтмена</ReactstrapButton>
            </Col>
          </Row>
          <div className="issue-content">
            <div className="issue-print-header">
              Плановый участок №{data.id}
            </div>
            <Row className="issue-info" noGutters>
              <Col sm="12">
                {fields.map(f => <Row className="mb-3">
                  <Col sm="3" className="bold">
                    {f.fieldTitle}:
                  </Col>
                  <Col sm="9">
                    {React.createElement(f.component, { data: data[f.fieldName] || undefined })}
                  </Col>
                </Row>)}
              </Col>
            </Row>
          </div>
          <div className="issue-content">
            <div className="issue-print-header mt-5 mb-3">
              Объекты      
            </div>
            <Map data={data} />
          </div>
          {defects.map(d => <div className="issue-content">
            <div className="issue-print-header mt-5 mb-3">
              Дефект №{d.number}
            </div>
            {defectsFields
              .filter(f => ![`Номер`, `Файлы`, `Файлы (устранение)`].includes(f.HeaderView()))
              .map(f => <Row className="mb-3">
                <Col sm="3" className="bold">
                  {React.createElement(f.HeaderView)}:
                </Col>
                <Col sm="9">
                  {React.createElement(f.ColumnView, { rowData: d })}
                </Col>
              </Row>)
            }
          </div>)}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default enhance(PrintIssueButton)
