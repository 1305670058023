import get from 'lodash/get'
import { pageDefaultConfig } from '@infotech/4sides-front-core'

const pageConfig = {
  ...pageDefaultConfig,
  changeDocumentTitle: ({ currentOrganization, currentDocumentTitle }) => {
    const organizationName = get(currentOrganization, `name`)
    return organizationName
      ? `Infotech.${organizationName}`
      : currentDocumentTitle
  }
}

export default pageConfig