import React from 'react'
import { REACT_APP_LOGO_TOP_BAR_NAME, CORE_URL, REACT_APP_WITH_CUSTOM_LOGO } from 'api/constants'
import LogoIMGComponent from 'components/LogoIMG'

const cheackConditionWithEnv = (env) => env && env !== `undefined`

const getHeaderConfig = () => {
  const headerConfig = {}
  const imageUrl = `${CORE_URL.replace(`/api`, ``)}/static/logo/logo.png`
  if (cheackConditionWithEnv(REACT_APP_WITH_CUSTOM_LOGO)) {
    headerConfig.withInfotechLogo = false
    headerConfig.Logo = () => <LogoIMGComponent src={imageUrl} />
  } else {
    headerConfig.withInfotechLogo = true
  }
  if (cheackConditionWithEnv(REACT_APP_LOGO_TOP_BAR_NAME)) {
    headerConfig.topBarName = REACT_APP_LOGO_TOP_BAR_NAME
  }
  if (cheackConditionWithEnv(REACT_APP_WITH_CUSTOM_LOGO) && !cheackConditionWithEnv(REACT_APP_LOGO_TOP_BAR_NAME)) {
    headerConfig.topBarName = null
  }
  return headerConfig
}

export default getHeaderConfig
