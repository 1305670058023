import { schema } from 'normalizr'
import {
  actionCrudCreatorsFor,
  createSelectorForEntityKey,
  createEntitiesSelector,
} from '@infotech/lib-api-middleware'

export const STREETPARTS_DAY_SCHEMA_NAME = `dayStreetParts`
export const URL = `/inspection-day/planned-street-part`

export const streetPartsDaySchema = new schema.Entity(STREETPARTS_DAY_SCHEMA_NAME)

export const streetPartsDaySelector = createSelectorForEntityKey(STREETPARTS_DAY_SCHEMA_NAME)
export const streetPartsDayEntitiesSelector = createEntitiesSelector(STREETPARTS_DAY_SCHEMA_NAME)

export const streetPartsDayApiActions = actionCrudCreatorsFor(STREETPARTS_DAY_SCHEMA_NAME, URL, streetPartsDaySchema)