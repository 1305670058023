import panelConfig from './panelConfig'
import { CRUD_PATH, CRUD_PATH_PLURAL } from './constants'
import {
  moduleCrudConfig,
  CRUDRoute,
  defaultModuleConfig,
} from './CRUDConfig'

const moduleConfig = {
  defaultModuleConfig,
  CRUDRoute,
  CRUD_PATH,
  panelConfig,
  headerConfig: {
    list: {
      title: `Внеплановые осмотры (ночные)`,
      path : `/${CRUD_PATH_PLURAL}`,
    },
    item: {
      title: `Внеплановый осмотр (ночной)`,
      path : `/${CRUD_PATH}`,
    },
  },
  moduleCrudConfig,
}

export default moduleConfig
