import React from 'react'
import {
  compose,
  mapProps,
  withProps,
  branch,
  renderComponent,
} from 'recompose'
import moment from 'moment/moment'
import { withRouter } from 'react-router'
import isEmpty from 'lodash/isEmpty'

import { ApiTransport } from '@infotech/lib-api-middleware'
import { Spinner, timezoneEnhancer } from '@infotech/base-components'

import {
  getHistory,
  HISTORY_SCHEMA_NAME,
  historyApiActions,
} from 'api/history'
import HistoryElement from './Element'
import { CRUD_PATH as CRUD_PATH_DAY } from 'modules/StreetPartDayModule/constants'
import { CRUD_PATH as CRUD_PATH_NIGHT } from 'modules/StreetPartModule/constants'
import { CRUD_PATH as CRUD_PATH_EXPRESS_INSPECTION_DAY } from 'modules/ExpressInspectionsDayModule/constants'
import { CRUD_PATH as CRUD_PATH_EXPRESS_INSPECTION_NIGHT } from 'modules/ExpressInspectionsModule/constants'

const EmptyData = () => (
  <div className="history--empty">
    История отсутствует
  </div>
)

const DateFormatter = timezoneEnhancer(
  ({ value }) => value && moment(value).format(`DD.MM.YYYY HH:mm`)
)

const History = ({ data }) => (
  <div className="history">
    {
      data && data.map(({
        id,
        eventDate,
        description,
        userId,
      }) => {
        return description ? (
          <div className="history-element" key={id}>            
            <div className="date-formatter">
              <DateFormatter
                value={eventDate}
              />
            </div>
            <HistoryElement userId={userId} description={description}/>            
          </div>
        ) : null
      })
    }
  </div>
)

export default compose(
  mapProps((
    {
      data: {
        id,
      },
    }) => ({
      issueId: id,
    }
  )),
  withRouter,
  withProps(({ issueId, location: { pathname } }) => {
    let code = `sg-shift-issue`
    if (pathname.startsWith(CRUD_PATH_NIGHT, 1)) {
      code = `planned-street-part.night`
    }
    if (pathname.startsWith(CRUD_PATH_DAY, 1)) {
      code = `planned-street-part.day`
    }
    if (pathname.startsWith(CRUD_PATH_EXPRESS_INSPECTION_NIGHT, 1)) {
      code = `express-inspection.night`
    }
    if (pathname.startsWith(CRUD_PATH_EXPRESS_INSPECTION_DAY, 1)) {
      code = `express-inspection.day`
    }
    return {
      entity      : HISTORY_SCHEMA_NAME,
      apiAction   : historyApiActions.fetch,
      dataSelector: getHistory,
      query       : { 
        query: `(entityCode==${code};entityId==${issueId}),(groupedEntityCode==${code};groupedEntityId==${issueId})`,
        orderBy: `id desc`,
        limit: -1,
      },
      spinner     : Spinner,
    }
  }),
  ApiTransport,
  branch(
    ({ data }) => isEmpty(data),
    renderComponent(EmptyData)
  )
)(History)
