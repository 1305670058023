import { mapissuesModule } from '@infotech/4sides-front-core'
import { moduleCrudConfig as createModuleCrudConfig, mapissuesReducers } from '@infotech/mapissues'
import createFiltersConfig from '@infotech/filters'

import {
  getFormConfig,
  getFilterConfig,
  getViewConfig,
  getGridConfig,
  getAllColumns,
  getAllFilters,
  fgupViewList,
  defaultFilterList,
} from './fieldsConf'
import filterFormFields from './filterFormFields'
import { rightWidgetsConfig as rightWidgetsConfiguration } from '../widgets'
import StreetPartWidget from './widgets/StreetPartWidget'
import ExpressInspectionsWidget from './widgets/ExpressInspectionsWidget'
import History, { HISTORY_TAB_ID, HISTORY_TAB_TITLE } from 'widgets/History'

const { defaultModuleConfig } = mapissuesModule.config

const filtersConfig = createFiltersConfig({
  filterName     : `filters`,
  savedFiltersUrl: `/user-filters/issues/filters`,
})

const defaultFilterConfig = createFiltersConfig({
  filterName     : `defaultFilter`,
  savedFiltersUrl: `/user-filters/issues/filters/default`,
  useRSQL        : false,
})

const fgupDefaultModuleConfig = {
  ...defaultModuleConfig,
  getGridConfig,
  shouldRequestCount : false,
  getSortQueryParam  : undefined,
  parseSortQueryParam: undefined,
  getViewConfig,
  getFormConfig,
  filterFormFields,
  defaultViewList    : fgupViewList,
  routing            : {
    ...defaultModuleConfig.routing,
    list: {
      ...defaultModuleConfig.routing.list,
      getAllColumns,
      useQuickView: true,
      filter      : {
        ...defaultModuleConfig.routing.list.filter,
        getAllFilters,
        getFilterConfig,
        defaultFilterList,
        ...filtersConfig,
      },
      defaultFilter: {
        ...defaultFilterConfig,
      },
    },
    read: {
      ...defaultModuleConfig.routing.read,
      rightWidgetsConfiguration,
      rightWidgets: [
        ...defaultModuleConfig.routing.read.rightWidgets,
      ],
      bottomWidgets: [        
        {
          tabId    : `STREETPART_TAB`,
          component: StreetPartWidget,
          title    : `Плановые участки`,
          hide     : (featureList, data) => ((data.type.key !== `sg_shift_day`) && (data.type.key !== `sg_shift_night`)),
        },
        {
          tabId    : `EXPRESS_INSPECTIONS_TAB`,
          component:  ExpressInspectionsWidget,
          title    : `Внеплановые осмотры`,
          hide     : (featureList, data) => ((data.type.key !== `sg_shift_day`) && (data.type.key !== `sg_shift_night`)),
        },
        defaultModuleConfig.routing.read.bottomWidgets[0], // comments
        {
          tabId    : HISTORY_TAB_ID,
          component: History,
          title    : HISTORY_TAB_TITLE,
          hide     : (featureList) => !featureList[`issue.history.read`],
        },
      ],
    },
  },
}

export const moduleCrudConfig = createModuleCrudConfig(fgupDefaultModuleConfig)

export default {
  ...mapissuesModule,
  config: {
    ...mapissuesModule.config,
    moduleCrudConfig,
    CRUDRoute: () => mapissuesModule.config.CRUDRoute(moduleCrudConfig),
  },
  reducers: {
    ...mapissuesModule.reducers,
    ...mapissuesReducers,
  },
}
