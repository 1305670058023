import {
  defaulCrudConfigMaker,
  CRUDRouteMaker,
} from '@infotech/crud'
import { URL } from 'api/streetPartsDay'
import { CRUD_PATH } from './constants'
import { defaultModuleConfig as nightModuleConfig } from '../StreetPartModule/CRUDConfig'

export const defaultModuleConfig = {
  ...nightModuleConfig,
  routing: {
    ...nightModuleConfig.routing,
    list: {
      ...nightModuleConfig.routing.list,
      title : `Плановые участки (дневные)`,
      exportToFileAsyncConfig: {
        ...nightModuleConfig.routing.list.exportToFileAsyncConfig,
        fixedEntity: `sg-day-planned-street-part`,
      },
    },
  },
}

export const moduleCrudConfig = (moduleConfig = defaultModuleConfig, crudPath = CRUD_PATH, apiUrl = URL) => (
  defaulCrudConfigMaker(moduleConfig, crudPath, apiUrl)
)

export const CRUDRoute = (config = moduleCrudConfig(), path = CRUD_PATH) => CRUDRouteMaker(config, path)
