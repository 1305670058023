import { rightWidgetsAdministrationConfig as mapissuesRightWidgetsAdministrationConfig } from '@infotech/mapissues'
import {
  administrationConfigAgreementStartWidget,
  administrationConfigAgreementTasksWidget,
  AgreementStartWidget,
  agreementStartWidgetId,
  AgreementTasksWidget,
  agreementTasksWidgetId,
} from '@infotech/agreement'
import { mapissuesModule } from '@infotech/4sides-front-core'

const { defaultModuleConfig } = mapissuesModule.config

export const rightWidgetsAdministrationConfig = [
  ...mapissuesRightWidgetsAdministrationConfig,
  administrationConfigAgreementStartWidget,
  administrationConfigAgreementTasksWidget,
]

export const rightWidgetsConfig = {
  ...defaultModuleConfig.routing.read.rightWidgetsConfiguration,
  rightWidgetsMapping: {
    ...defaultModuleConfig.routing.read.rightWidgetsConfiguration.rightWidgetsMapping,
    [agreementStartWidgetId]    : AgreementStartWidget,
    [agreementTasksWidgetId]    : AgreementTasksWidget,
  },
}
