import { schema } from 'normalizr'
import {
  actionCrudCreatorsFor,
  createSelectorForEntityKey,
  createEntitiesSelector,
} from '@infotech/lib-api-middleware'

export const EXPRESS_INSPECTIONS_SCHEMA_NAME = `expressInspections`
export const URL = `/inspection-night/express-inspection`

export const expressInspectionsSchema = new schema.Entity(EXPRESS_INSPECTIONS_SCHEMA_NAME)

export const expressInspectionsSelector = createSelectorForEntityKey(EXPRESS_INSPECTIONS_SCHEMA_NAME)
export const expressInspectionsEntitiesSelector = createEntitiesSelector(EXPRESS_INSPECTIONS_SCHEMA_NAME)

export const expressInspectionsApiActions = actionCrudCreatorsFor(EXPRESS_INSPECTIONS_SCHEMA_NAME, URL, expressInspectionsSchema)