import {
  createCoreDictActions,
  addDictRequestActions,
} from '@infotech/4sides-core-dictionaries'

import { CORE_URL } from 'api/constants'

const coreDictList = [
  {
    name       : `lo_class`,
    url        : `${CORE_URL}/reference/objects/data/all/lo_class/v1`,
    idAttribute: `code`,
    versionRule: `lo_class`,
  },
  {
    name       : `lo_tag`,
    url        : `${CORE_URL}/reference/objects/data/all/lo_tag/v1`,
    idAttribute: `code`,
    versionRule: `lo_tag`,
  },
  {
    name       : `electrical_network`,
    url        : `${CORE_URL}/reference/objects/data/all/electrical_network/v1`,
    idAttribute: `name`,
    versionRule: `electrical_network`,
  },
  {
    name       : `lo_inspection_periodicity`,
    url        : `${CORE_URL}/reference/objects/data/all/lo_inspection_periodicity/v1`,
    idAttribute: `code`,
    versionRule: `lo_inspection_periodicity`,
  },
  {
    name       : `district`,
    url        : `${CORE_URL}/reference/objects/data/all/district/v1`,
    idAttribute: `okato`,
    versionRule: `district`,
  },
  {
    name       : `borough`,
    url        : `${CORE_URL}/reference/objects/data/all/borough/v1`,
    idAttribute: `okato`,
    versionRule: `borough`,
  },
  {
    name       : `tag`,
    url        : `${CORE_URL}/reference/objects/data/all/tag/v1`,
    idAttribute: `code`,
    versionRule: `tag`,
  },
  {
    name       : `employee`,
    url        : `${CORE_URL}/reference/objects/data/all/employee/v1`,
    idAttribute: `code`,
    versionRule: `employee`,
  },
  {
    name       : `category`,
    url        : `${CORE_URL}/reference/objects/data/all/category/v1`,
    idAttribute: `code`,
    versionRule: `category`,
  },
  {
    name       : `lo_component`,
    url        : `${CORE_URL}/reference/objects/data/all/lo_component/v1`,
    idAttribute: `code`,
    versionRule: `lo_component`,
  },
  {
    name       : `defect_type`,
    url        : `${CORE_URL}/reference/objects/data/all/defect_type/v1`,
    idAttribute: `code`,
    versionRule: `defect_type`,
  },
  {
    name       : `defect_labels`,
    url        : `${CORE_URL}/reference/objects/data/all/defect_labels/v1`,
    idAttribute: `code`,
    versionRule: `defect_labels`,
  },
  {
    name       : `street_part_type`,
    url        : `${CORE_URL}/reference/objects/data/all/street_part_type/v1`,
    idAttribute: `code`,
    versionRule: `street_part_type`,
  },
  {
    name       : `street_part_subtype`,
    url        : `${CORE_URL}/reference/objects/data/all/street_part_subtype/v1`,
    idAttribute: `code`,
    versionRule: `street_part_subtype`,
  },
]

const coreDictActions = createCoreDictActions(coreDictList)

addDictRequestActions(coreDictActions)

export default coreDictList
