import { createSelectorForEntityKey, actionCrudCreatorsFor } from '@infotech/lib-api-middleware'
import { schema } from 'normalizr'
import { CORE_URL } from './constants'

export const HISTORY_SCHEMA_NAME = `sg_history`
export const historySchema = new schema.Entity(HISTORY_SCHEMA_NAME)

const URL = `${CORE_URL}/history-service/history`

export const historyApiActions = actionCrudCreatorsFor(HISTORY_SCHEMA_NAME, URL, historySchema)

export const getHistory = createSelectorForEntityKey(HISTORY_SCHEMA_NAME)
