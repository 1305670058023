import React from 'react'
import {
  defaultProps,
  compose,
  withPropsOnChange,
  withState,
} from 'recompose'
import { connect } from 'react-redux'
import { Polyline } from 'react-leaflet'
import isEmpty from 'lodash/isEmpty'
import {
  CALL_API,
} from '@infotech/lib-api-middleware'
import createAddressLabel, { transformEntrance, transformFlat } from './createAddressLabel'
import { LAYER_NAME, LAYER_ID } from './constants'


export default compose(
  withState(`issueCoordinates`, `setIssueCoordinates`, null),
  connect(null, (dispatch, { setIssueCoordinates }) => {
    return {
      fetchCoordinates: (id) => {
        const request = {
          type      : `ISSUE_COORDINATES_FETCH`,
          [CALL_API]: {
            url: `/sg-device-backend/event/${id}/build?query=eventType==tick`,
          },
        }
        dispatch(request).then((response) => {
          if(!response || response.error) {
              return
          }
          setIssueCoordinates(response.response.json)
        })
      },
    }
  }),
  withPropsOnChange(
    [`allData`],
    ({ allData, fetchCoordinates, issueCoordinates }) => {
      if (allData?.id && !issueCoordinates) {
        fetchCoordinates(allData.id)
      }
    },
  ),
  withPropsOnChange([`issueCoordinates`], ({ issueCoordinates }) => {
    let customLayers = []
    if (isEmpty(issueCoordinates?.coordinates)) {
      return ({ customLayers })
    }
    const positions = issueCoordinates?.coordinates.flat().map(t => t.reverse())
    customLayers = [
      {
        layerName: LAYER_NAME,
        component: (props) => (
          <Polyline
            positions={positions}
            {...props}
            weight={10}
          />
        ),
        id     : LAYER_ID,
        checked: true,
      },
    ]
    return ({
      customLayers,
    })
  }),
  defaultProps({
    createAddressLabel: createAddressLabel({
      formatLabels: [
        { id: `city` },
        { id: `district` },
        { id: `village` },
        { id: `street` },
        { id: `house` },
        {
          id       : `entrance`,
          transform: transformEntrance,
        },
        {
          id       : `flat`,
          transform: transformFlat,
        },
      ],
    }),
  })
)
