import getEnvironmentVariable from '@infotech/helper-get-enviroment-variable'

export const REACT_APP_LOGO_TOP_BAR_NAME = `${getEnvironmentVariable(
  `REACT_APP_LOGO_TOP_BAR_NAME`,
  process.env.REACT_APP_LOGO_TOP_BAR_NAME,
)}`

export const CORE_URL = `${getEnvironmentVariable(
  `REACT_APP_API`,
  process.env.REACT_APP_API,
)}`

export const SG_REFERENCE_HOST = `${getEnvironmentVariable(
  `REACT_APP_SG_REFERENCE_HOST`,
  process.env.REACT_APP_SG_REFERENCE_HOST,
)}`

export const REACT_APP_WITH_CUSTOM_LOGO = `${getEnvironmentVariable(
  `REACT_APP_WITH_CUSTOM_LOGO`,
  process.env.REACT_APP_WITH_CUSTOM_LOGO,
)}`

export const REACT_APP_DOCUMENT_TITLE = `${getEnvironmentVariable(
  `REACT_APP_DOCUMENT_TITLE`,
  process.env.REACT_APP_DOCUMENT_TITLE,
)}`

export const REACT_APP_DOCUMENT_LOGO = `${getEnvironmentVariable(
  `REACT_APP_DOCUMENT_LOGO`,
  process.env.REACT_APP_DOCUMENT_LOGO,
)}`

export const REACT_APP_FORM_CODES = `${getEnvironmentVariable(
  `REACT_APP_FORM_CODES`,
  process.env.REACT_APP_FORM_CODES,
)}`
