import { createSelector } from 'reselect'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import get from 'lodash/get'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import { getIssue, getIssueTypeById, dictSelectors } from '@infotech/mapissues'

const omitFieldsAlways = [
  `assignedDateTo`,
  `assignedDateFrom`,
  `addressFields`,
]

const omitFieldsByTypeCodeMap = {
//  [ISSUE_TYPE_CODES.order]            : [`point`, `addressFields`],
}

const viewFieldsByStatusCodeMap = {
//  [ISSUES_STATUS_CODES.confirmation]: [`accdate`, `ex_use`],
}

const filterFormFields = createSelector([
  (state, formValues) => getIssue(state, get(formValues, `id`)) || {},
  (state, formValues) => getIssueTypeById(state, get(formValues, `typeId`)) || {},
  dictSelectors.statuses,
  (state, formValues, formFields) => formFields,
], (
  { statusId },
  { code: issueTypeCode },
  statuses,
  formFields
) => {
  const issueStatusCode = get(find(statuses, { id: statusId }), `code`)
  const omitFields = [
    ...omitFieldsAlways,
    ...get(omitFieldsByTypeCodeMap, issueTypeCode, []),
  ]
  forEach(viewFieldsByStatusCodeMap, (fields, statusCode) => (
    statusCode !== issueStatusCode && omitFields.push(...fields)
  ))
  return filter(formFields, (value) => !includes(omitFields, value))
})

export default filterFormFields