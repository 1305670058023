import React from 'react'
import { connect } from 'react-redux'

import { getEntity } from '@infotech/lib-api-middleware'

const ISSUE_MAKER = `` // пользователь по умолчанию

const authorEnhancer = connect(
  (state, { userId }) => ({
    user: getEntity(state, `users`, userId),
  }),
)

const HistoryElement = ({ user, description }) => (
  <div>
    { user
      ? (
        <strong>
          {user.name}{`: `}
        </strong>
      )
      : (
        <strong>
          {ISSUE_MAKER}
        </strong>
      )
    }
    {description}
  </div>
)

export default authorEnhancer(HistoryElement)
