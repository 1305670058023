import {
  defaulCrudConfigMaker,
  CRUDRouteMaker,
} from '@infotech/crud'
import { URL } from 'api/expressInspectionsDay'
import { CRUD_PATH } from './constants'
import { defaultModuleConfig as nightModuleConfig } from '../ExpressInspectionsModule/CRUDConfig'

export const defaultModuleConfig = {
  ...nightModuleConfig,
  routing: {
    ...nightModuleConfig.routing,
    list: {
      ...nightModuleConfig.routing.list,
      title : `Внеплановые осмотры (дневные)`,
    },
  },
}

export const moduleCrudConfig = (moduleConfig = defaultModuleConfig, crudPath = CRUD_PATH, apiUrl = URL) => (
  defaulCrudConfigMaker(moduleConfig, crudPath, apiUrl)
)

export const CRUDRoute = (config = moduleCrudConfig(), path = CRUD_PATH) => CRUDRouteMaker(config, path)
