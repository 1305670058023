import createApp from '@infotech/4sides-front-core'
import agreementModuleExtension, { AgreementTasksUpdater } from '@infotech/agreement'
import mapissuesConfig from './mapissuesConfig'
import administratorConfig from './administratorConfig'
import serverNotificationsConfig from './notificationsConfig'
import reportsConfig from './reportsConfig'
import streetPartsConfig from 'modules/StreetPartModule'
import streetPartsDayConfig from 'modules/StreetPartDayModule'
import expressInspectionsConfig from 'modules/ExpressInspectionsModule'
import expressInspectionsDayConfig from 'modules/ExpressInspectionsDayModule'
import pageConfig from './pageConfig'
import getHeaderConfig from './headerConfig'
import { REACT_APP_DOCUMENT_TITLE } from './api/constants'
import DocumentLogoComponent from './components/DocumentLogo'
import coreDictList from './dictionaries'
import streetPartReducer from './modules/StreetPartModule/reducer'

import 'index.scss'

document.title = REACT_APP_DOCUMENT_TITLE

createApp({
  DocumentLogoComponent,
  extensions: [
    streetPartsConfig,
    streetPartsDayConfig,
    expressInspectionsConfig,
    expressInspectionsDayConfig,
    agreementModuleExtension,
    reportsConfig,
  ],
  initialStoreState: {
    documentTitle: {
      brand          : REACT_APP_DOCUMENT_TITLE,
      route          : null,
      activeModalForm: null,
    },
  },
  headerConfig                : getHeaderConfig(),
  customMiddlewares           : [AgreementTasksUpdater],
  mapissuesModuleExtension    : mapissuesConfig,
  administratorModuleExtension: administratorConfig,
  serverNotificationsConfig,
  pageConfig,
  customReducers               : {
    ...streetPartReducer,
  },
  customSagas                 : [],
})
