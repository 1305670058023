import { WithTooltip } from '@infotech/base-components'
import { CRUD_PATH_PLURAL } from './constants'

import { ReactComponent as Sun } from "./sun.svg"

const Icon = WithTooltip({
  placement      : `right`,
  tooltip        : `Внеплановые осмотры`,
  TargetComponent: Sun,
})

export default {
  id  : `dayExpressInspections`,
  name: `Внеплановые осмотры (дневные)`,
  to  : `/${CRUD_PATH_PLURAL}`,
  icon: Icon,
  hide: (capabilities) => !capabilities[`sg.express-inspections.day.access`],
}
