import { administratorModule } from '@infotech/4sides-front-core'
import { rightWidgetsAdministrationConfig } from '../widgets'

export default {
  ...administratorModule,
  config: {
    ...administratorModule.config,
    CRUDRoute: () => administratorModule.config.CRUDRoute({ rightWidgetsAdministrationConfig }),
  },
}
