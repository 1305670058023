import { CALL_API } from '@infotech/lib-api-middleware'
import { CORE_URL } from 'api/constants'

export const objectsFetchApiAction = (inventoryNumber) => ({
    type      : `STREETPART_OBJECTS_FETCH`,
    [CALL_API]: {
        url    : `${CORE_URL}/reference/ds/data/object/sg_lo?limit=-1`,
        options: {
            method: `POST`,        
            body: {
                "selectProperties": [
                    "$id$",
                    "point",
                    "reconstruction",
                    "lo_class",
                    "code",
                    "lamps",
                ],
                "filter": {
                    "type": "AND",
                    operands: [{
                        "left": {
                            "type": "COMMON_ATTRIBUTE",
                            "attribute": "street_part.number"
                        },
                        "type": "EQUAL",
                        "right": {
                            "type": "constant",
                            "dataType": {
                                "type": "LONG"
                            },
                            "value": inventoryNumber,
                        }
                    }, {
                        "type": "NULL", 
                        "operand": {
                            "type": "field", 
                            "field": "$removed_at$"
                        },
                    }],
                }
            },
        },
    },
})

const getShiftMeta = (shiftId) => {
    if (shiftId === 1) {
        return {                                
            "link": {
                "key": "night",
                "value": "Ночная"
            },                                
            "id": 1
        }
    }
    if (shiftId === 2) {
        return {                                
            "link": {
                "key": "day",
                "value": "Дневная"
            },                                
            "id": 2
        }
    }
}

export const defectsFetchApiAction = (objectsIds, shiftId = 1) => ({
    type      : `STREETPART_DEFECTS_FETCH`,
    [CALL_API]: {
        url    : `${CORE_URL}/reference/ds/data/query/sg_mapissues_defect_with_elimination?limit=-1`,
        options: {
            method: `POST`,        
            body: {
                "selectProperties": [
                    "$id$",
                    "$lo_id$",
                    "number",
                    "attachments",
                    "ext_issue_id",
                    "ext_planned_street_part_id",
                    "continuous",
                    "sequential",
                    "lamps",
                    "twenty_four",
                    "registration_date",
                    "due_date",
                    "elimination_date",
                    "confirm_date",
                    "comment",
                    "lo",
                    "lo_class",
                    "defect_type",
                    "inspector",
                    "ext_issue_id_",
                    "ext_planned_street_part_id_",
                    "street_part",
                    "shift_type",
                    "electrical_network",
                    "ddy",
                    "outbound_magistral",
                    "ahp_category",
                    "lo_component",
                    "category",
                    "periodicity",
                    "district",
                    "borough",
                    "lo_point",
                    "elimination_comment",
                    "elimination_attachments",
                    "checkup_count",
                    "elimination_number",
                    "elimination_reject_date",
                    "ddy_notification_dispatcher"
                ],
                "sorting": [{
                    "property":"lo",
                    "direction":"ASC"
                }],
                "filter": {
                    "type": "AND",
                    operands: [{
                        "left": {
                            "type": "field",
                            "field": "$lo_id$"
                        },
                        "type": "IN",
                        "right": {
                            "type": "constant",
                            "dataType": {
                                "type": "LONG"
                            },
                            "value": [...objectsIds]
                        }
                    }, {
                        "type": "NULL", 
                        "operand": {
                            "type": "field", 
                            "field": "confirm_date"
                        },
                    }, {
                        "type": "EQUAL",
                        "left": {
                            "type": "field",
                            "field": "shift_type"
                        },
                        "right": {
                            "type": "constant",
                            "dataType": {
                                "code": "shift_type",
                                "version": "v1",
                                "type": "OBJECT_LINK"
                            },
                            "value": getShiftMeta(shiftId)
                        }
                    }]
                }
            },
        },
    },
})

export const historyFetchApiAction = (id, shiftId = 1) => ({
    type      : `STREETPART_HISTORY_FETCH`,
    [CALL_API]: {
        url    : `${CORE_URL}/inspection-${(shiftId === 1) ? `night`: `day`}/snapshots/street-part/${id}`,
        options: {
            method: `GET`,
        },
    },
})