import React from 'react'
import { Link } from 'react-router-dom'
import {
  compose, withState, withPropsOnChange, 
} from 'recompose'
import { connect } from 'react-redux'
import queryString from 'query-string'
import debounce from 'lodash/debounce'

import { WidgetTable, DateFormatter, Spinner, Collapse, } from '@infotech/base-components'
import { withToken } from '@infotech/auth'
import { CALL_API } from '@infotech/lib-api-middleware'
import { CORE_URL } from 'api/constants'

import { STREETPART_DEFECTS, STREETPART_SELECTED_OBJECT, selectObject } from '../../reducer'
import { SG_REFERENCE_HOST } from 'api/constants'

import FileViewer from './components/FileViewer'

import './defects.scss'

export const columns = [ {
    HeaderView    : () => `Номер`,
    width         : `70px`,
    ColumnView    : ({ rowData: { number } }) => <div><a 
      target="_blank"
      rel="noopener noreferrer"
      href={`${SG_REFERENCE_HOST}/integration/view/open/dv/sg_defect?key=${number}`}      
    >{number}</a></div>,
  }, {
    HeaderView    : () => `Тип дефекта`,
    width         : `140px`,
    ColumnView    : ({ rowData: { defect_type } }) => <div>{defect_type?.link?.value}</div>,
  }, {
    HeaderView    : () => `Компонент объекта`,
    width         : `120px`,
    ColumnView    : ({ rowData: { lo_component } }) => <div>{lo_component?.link?.value}</div>,
  }, {
    HeaderView    : () => `Объект`,
    width         : `80px`,
    ColumnView    : ({ rowData: { lo } }) => <div><a 
      target="_blank"
      rel="noopener noreferrer"
      href={`${SG_REFERENCE_HOST}/integration/view/open/dv/sg_lo?key=${lo?.link?.key}`}      
    >{lo?.link?.value}</a></div>,
  }, {
    HeaderView    : () => `Кол-во светильников`,
    width         : `110px`,
    ColumnView    : ({ rowData: { lamps } }) => <div>{lamps}</div>,
  }, {
    HeaderView    : () => `Длительно негорящий`,
    width         : `110px`,
    ColumnView    : ({ rowData: { continuous } }) => <div>{continuous && `Да`}</div>,
  }, {
    HeaderView    : () => `Последовательно негорящий`,
    width         : `135px`,
    ColumnView    : ({ rowData: { sequential } }) => <div>{sequential && `Да`}</div>,
  }, {
    HeaderView    : () => `Наряд-задание`,
    width         : `80px`,
    ColumnView    : ({ rowData: { ext_issue_id } }) => <div><Link to={`/issue/read/${ext_issue_id}`}>{ext_issue_id}</Link></div>,
  }, {
    HeaderView    : () => `Плановый участок`,
    width         : `90px`,
    ColumnView    : ({ rowData: { ext_planned_street_part_id, shift_type } }) => <div>
      <Link to={`/${shift_type?.link?.key === `day` ? `dayStreetPart` : `streetPart`}/read/${ext_planned_street_part_id}`}>
        {ext_planned_street_part_id}
      </Link>
    </div>,
  }, {
    HeaderView    : () => `Инспектор`,
    width         : `150px`,
    ColumnView    : ({ rowData: { inspector } }) => <div>{inspector?.link?.value}</div>,
  }, {
    HeaderView    : () => `Время выявления`,
    width         : `110px`,
    ColumnView    : ({ rowData: { registration_date } }) => <div><DateFormatter value={registration_date} emptyData=" "/></div>,
  }, {
    HeaderView    : () => `Срок устранения`,
    width         : `110px`,
    ColumnView    : ({ rowData: { due_date } }) => <div><DateFormatter value={due_date} emptyData=" "/></div>,
  }, {
    HeaderView    : () => `ДДУ`,
    width         : `60px`,
    ColumnView    : ({ rowData: { ddy } }) => <div>{ddy && `Да`}</div>,
  }, {
    HeaderView    : () => `24 часа`,
    width         : `100px`,
    ColumnView    : ({ rowData: { twenty_four } }) => <div>{twenty_four && `Да`}</div>,
  }, {
    HeaderView    : () => `Вылетные магистрали`,
    width         : `100px`,
    ColumnView    : ({ rowData: { outbound_magistral } }) => <div>{outbound_magistral && `Да`}</div>,
  }, {
    HeaderView    : () => `Категория АХП`,
    width         : `100px`,
    ColumnView    : ({ rowData: { ahp_category } }) => <div>{ahp_category}</div>,
  }, {
    HeaderView    : () => `Кол-во повторных выявлений`,
    width         : `150px`,
    ColumnView    : ({ rowData: { checkup_count } }) => <div>{checkup_count}</div>,
  }, {
    HeaderView    : () => `Комментарий`,
    width         : `150px`,
    ColumnView    : ({ rowData: { comment } }) => <div>{comment}</div>,
  }, {
    HeaderView    : () => `Сообщено диспетчеру`,
    width         : `150px`,
    ColumnView    : ({ rowData: { ddy_notification_dispatcher } }) => <div>{ddy_notification_dispatcher}</div>,
  }, {
    HeaderView    : () => `Файлы`,
    width         : `200px`,
    ColumnView    : ({ rowData: { attachments }, token }) => <div>
      <FileViewer images={attachments?.map(a => ({ 
        url: `${SG_REFERENCE_HOST}/api/filestorage/images/file/${a.id}/content?${queryString.stringify({token})}`, 
        className: `streetmap-defects-file-link`,
        ...a,
      }))} />
    </div>,
  }, {
    HeaderView    : () => `Время отчёта РЭС`,
    width         : `110px`,
    ColumnView    : ({ rowData: { elimination_date } }) => <div><DateFormatter value={elimination_date} emptyData=" "/></div>,
  },{
    HeaderView    : () => `Выполненные работы`,
    width         : `150px`,
    ColumnView    : ({ rowData: { elimination_comment } }) => <div>{elimination_comment}</div>,
  }, {
    HeaderView    : () => `Файлы (устранение)`,
    width         : `200px`,
    ColumnView    : ({ rowData: { elimination_attachments: attachments }, token }) => <div>
      <FileViewer images={attachments?.map(a => ({ 
        url: `${SG_REFERENCE_HOST}/api/filestorage/images/file/${a.id}/content?${queryString.stringify({token})}`, 
        className: `streetmap-defects-file-link`,
        ...a,
      }))} />
    </div>,
  },
]

const RowContainer = ({ children, selectObject, selectedObject, rowData: { lo: { id }, lo_point } }) => <div 
  data-object={id}
  className={`streetmap-defects-row ${(selectedObject.$id$ === id) ? `streetmap-defects-row-selected` : ``}`}
  onClick={() => selectObject({
    "$id$": id,
    point: { ...lo_point },
  })}
>{children}</div>

const initialFields = []
columns.forEach(() => initialFields.push(true))

const enhancer = compose(
  withState(`selectedColumns`, `setSelectedColumns`, [...initialFields]),
  withState(`fieldsExist`, `setFieldsExist`),
  connect((state, { data: { id } }) => ({
    defects: state[STREETPART_DEFECTS][id],
    selectedObject: state[STREETPART_SELECTED_OBJECT][id] || {},
  }), (dispatch, { data: { id }, setSelectedColumns, setFieldsExist, fieldsExist, selectedColumns }) => ({
    selectObject: (object) => {
      dispatch(selectObject(id, object))
    },
    fetchFields: () => {
      const request = {
        type      : `STREETPART_DEFECTS_FIELDS_FETCH`,
        [CALL_API]: {
          url    : `${CORE_URL}/user-settings/user-settings/defectsFields`,
          options: {
            method: `GET`,        
          }
        }
      }
      dispatch(request).then((response)=>{
        if(response.error) {
          setFieldsExist(false)
          return
        }
        setFieldsExist(true)
        if(response.response.json?.value?.length === initialFields.length) {
          setSelectedColumns(response.response.json.value)
        }
      })
    },
    setFields: () => {
      if(fieldsExist === undefined) {
        return
      }
      const request = {
        type      : fieldsExist ? `STREETPART_DEFECTS_FIELDS_PUT` : `STREETPART_DEFECTS_FIELDS_POST`,
        [CALL_API]: {
          url    : `${CORE_URL}/user-settings/user-settings${fieldsExist ? `/defectsFields` : ``}`,
          options: {
            method: fieldsExist ? `PUT` : `POST`,   
            body: {
              value: selectedColumns,
              key: fieldsExist ? undefined : `defectsFields`,
            }
          }
        }
      }
      dispatch(request).then((response)=>{
        if(response.error) {
          return
        }
        setFieldsExist(true)
      })
    },
  })),
  withPropsOnChange([], ({ fetchFields }) => fetchFields()),
  withPropsOnChange([`selectedColumns`], ({ setFields }) => setFields()),
  withToken,
)

const debounced = debounce(function(){
  const cont = document.querySelector(`.issue-layout`)
  const cont2 = document.querySelector(`.streetmap-defects-conainer .widget-table-container`)
  const header = document.querySelector(`.streetmap-defects-conainer .widget-table__header-row`)
  if (cont && cont2 && header) {
    header.style.top = Math.max(0, (cont.scrollTop - cont2.offsetTop)) + `px`
  }
}, 50)

const Defects = ({ defects, token, selectObject, selectedObject, selectedColumns, setSelectedColumns, fieldsExist }) => {
  if (!defects) {
    return <div className="streetmap-nodefects">
      <Spinner/>
    </div>
  }
  if (!defects.length) {
    return <div className="streetmap-nodefects">
      Дефектов нет.
    </div>
  }

  const cont = document.querySelector(`.issue-layout`)
  cont && (cont.onscroll = debounced)
  
  return <>
    {(fieldsExist !== undefined) && <div className="streetmap-defects-fields">
      <Collapse title="Настройка полей" isOpen={false}>
        <div className="mb-2 ml-2">
          {columns.map((c, i) => <button 
            key={i}
            className={`btn btn-sm ${selectedColumns[i] ? `btn-secondary`: `btn-light`}`}
            onClick={() => setSelectedColumns(selectedColumns.map((v,j) => (i === j) ? !v : v))}
          >{c.HeaderView()}</button>)}
        </div>
      </Collapse>
    </div>}
    <div className="streetmap-defects-conainer"><WidgetTable
      data={defects}
      columns={columns.filter((c, i) => selectedColumns[i])}  
      token={token}
      RowContainer={RowContainer}
      selectObject={selectObject}
      selectedObject={selectedObject}
    /></div>
  </>
}

export default enhancer(Defects)