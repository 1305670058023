import React from 'react'
import {
  compose,
  nest,
  withProps,
  withPropsOnChange,
} from 'recompose'
import keyBy from 'lodash/keyBy'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import values from 'lodash/values'

import { mapissuesModule } from '@infotech/4sides-front-core'
import { getEntities } from '@infotech/lib-api-middleware'
import {
  fieldApps,
  fieldTypes,
  typesConf,
  HEADING,
  BODY,
  getAppConfigurator,
} from '@infotech/view-config'
import {
  Text,
  WithTitle,
  InputHelper,
  DateRange,
  EntityLink,
  Multiselect,
} from '@infotech/base-components'
import { createGetColumnsConf } from '@infotech/crud'
import {
  dateRangeFilters,
  stringListFilters,
  numberFilters,
  textSearchFilters,
} from '@infotech/filters'

import { SG_REFERENCE_HOST } from 'api/constants'
import { CRUD_PATH } from './constants'
import { CRUD_PATH as CRUD_PATH_DAY } from '../ExpressInspectionsDayModule/constants'
import { 
  ReferenceEntityViewGenerator, 
  EmployeeFilter, 
  EmployeeField, 
  DateFormatterNoTime, 
  generateFullName,
  DebouncedUIKitInput,
  IssueLink,
  ifDayEntity,
} from '../StreetPartModule/fieldsConf'
const { defaultModuleConfig } = mapissuesModule.config

export const ReferenceLinkGenerator = (entity) => ({ data, value = data }) => (
  <a 
    target="_blank"
    rel="noopener noreferrer"
    href={`${SG_REFERENCE_HOST}/integration/view/open/dv/${entity}?key=${value}`}
  >{value}</a>
)

const IdFormatter = compose(
  withRouter,
  withPropsOnChange([`location`], ({ location: { pathname } }) => ({
    entity: ifDayEntity(pathname) ? CRUD_PATH_DAY : CRUD_PATH,
  })),
)(EntityLink)

const UserField = compose(
  connect((state) => ({
    users: getEntities(state, `users`),
  })),
  withPropsOnChange([`users`], ({ users }) => ({
    data      : values(users),
    textField: `name`,
    valueField: `id`,
    filter: `contains`,
  })),
  withProps(({ input: { onChange, value } }) => ({
    onSelect: val => onChange(val.code),
    defaultValue: value,
  })),
)(Multiselect)

export const fieldsConf = {
  id: {
    type: fieldTypes.TEXT,
    name: `Id`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Id`,
        formatter: IdFormatter,
        sortable : true,
        width    : 60,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, Text),
        title    : `Id`,
      },
      [fieldApps.FILTER]: {
        title        : `Id`,
        component    : DebouncedUIKitInput,
        parseValue   : numberFilters.parseValue,
        valueToFilter: numberFilters.valueToFilter,
      },
    },
  },
  inspector: {
    type: fieldTypes.TEXT,
    name: `Инспектор`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Инспектор`,
        formatter: ReferenceEntityViewGenerator(`employee`, generateFullName),
        sortable : true,
        width    : 240,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceEntityViewGenerator(`employee`, generateFullName)),
        title    : `Инспектор`,
      },
      [fieldApps.FILTER]: {
        title        : `Инспектор`,
        component    : EmployeeFilter,
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
      [fieldApps.FORM]: {
        place    : BODY,
        title    : `Инспектор`,
        component: nest(InputHelper, EmployeeField),
      },
    },
  },
  creator: {
    type: fieldTypes.TEXT,
    name: `Создатель`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Создатель`,
        formatter: ReferenceEntityViewGenerator(`users`),
        sortable : true,
        width    : 240,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceEntityViewGenerator(`users`)),
        title    : `Создатель`,
      },
      [fieldApps.FILTER]: {
        title        : `Создатель`,
        component    : UserField,
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
    },
  },
  createDate: {
    type: fieldTypes.TEXT,
    name: `Дата создания`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Дата создания`,
        formatter: DateFormatterNoTime,
        sortable : true,
        width    : 110,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, DateFormatterNoTime),
        title    : `Дата создания`,
      },
      [fieldApps.FILTER]: {
        title        : `Дата создания`,
        component    : DateRange,
        parseValue   : dateRangeFilters.parseValue,
        valueToFilter: dateRangeFilters.valueToFilter,
      },
    },
  },
  factDate: {
    type: fieldTypes.TEXT,
    name: `Дата выполнения`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Дата выполнения`,
        formatter: DateFormatterNoTime,
        sortable : true,
        width    : 130,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, DateFormatterNoTime),
        title    : `Дата выполнения`,
      },
      [fieldApps.FILTER]: {
        title        : `Дата выполнения`,
        component    : DateRange,
        parseValue   : dateRangeFilters.parseValue,
        valueToFilter: dateRangeFilters.valueToFilter,
      },
    },
  },
  route: {
    type: fieldTypes.TEXT,
    name: `Наряд-задание`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Наряд-задание`,
        formatter: IssueLink,
        sortable : true,
        width    : 110,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, IssueLink),
        title    : `Наряд-задание`,
      },
      [fieldApps.FILTER]: {
        title        : `Наряд-задание`,
        component    : DebouncedUIKitInput,
        parseValue   : textSearchFilters.parseValue,
        valueToFilter: textSearchFilters.valueToFilter,
      },
    },
  },
  defectNumber: {
    type: fieldTypes.TEXT,
    name: `Дефект`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Дефект`,
        sortable : true,
        formatter: ReferenceLinkGenerator(`sg_defect`),
        width    : 80,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceLinkGenerator(`sg_defect`)),
        title    : `Дефект`,
      },
      [fieldApps.FILTER]: {
        title        : `Дефект`,
        component    : DebouncedUIKitInput,
        parseValue   : numberFilters.parseValue,
        valueToFilter: numberFilters.valueToFilter,
      },
    },
  },
  defectStatus: {
    type: fieldTypes.TEXT,
    name: `Статус осмотра`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Статус осмотра`,
        sortable : true,
        width    : 200,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, Text),
        title    : `Статус осмотра`,
      },
      [fieldApps.FILTER]: {
        title        : `Статус осмотра`,
        component    : DebouncedUIKitInput,
        parseValue   : textSearchFilters.parseValue,
        valueToFilter: textSearchFilters.valueToFilter,
      },
    },
  },
  streetNumber: {
    type: fieldTypes.TEXT,
    name: `Инв. №`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Инв. №`,
        sortable : true,
        formatter: ReferenceLinkGenerator(`sg_street_part`),
        width    : 80,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceLinkGenerator(`sg_street_part`)),
        title    : `Инв. №`,
      },
      [fieldApps.FILTER]: {
        title        : `Инв. №`,
        component    : DebouncedUIKitInput,
        parseValue   : numberFilters.parseValue,
        valueToFilter: numberFilters.valueToFilter,
      },
    },
  },
  streetName: {
    type: fieldTypes.TEXT,
    name: `Наименование`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Наименование`,
        sortable : true,
        width    : 180,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, Text),
        title    : `Наименование`,
      },
      [fieldApps.FILTER]: {
        title        : `Наименование`,
        component    : DebouncedUIKitInput,
        parseValue   : textSearchFilters.parseValue,
        valueToFilter: textSearchFilters.valueToFilter,
      },
    },
  },
  loNumber: {
    type: fieldTypes.TEXT,
    name: `Объект`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Объект`,
        sortable : true,
        formatter: ReferenceLinkGenerator(`sg_lo`),
        width    : 80,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceLinkGenerator(`sg_lo`)),
        title    : `Объект`,
      },
      [fieldApps.FILTER]: {
        title        : `Объект`,
        component    : DebouncedUIKitInput,
        parseValue   : numberFilters.parseValue,
        valueToFilter: numberFilters.valueToFilter,
      },
    },
  },
}

export const defaultColumnsList = [
  `id`,
  `createDate`,
  `creator`,
  `inspector`,
  `route`,
  `defectNumber`,
  `factDate`,
  `defectStatus`,
  `streetNumber`,
  `streetName`,
  `loNumber`,
]

export const defaultFormList = []

export const defaultFormListEdit = [
  `inspector`,
]

export const defaultViewList = [
  `id`,
  `createDate`,
  `creator`,
  `inspector`,
  `route`,
  `defectNumber`,
  `factDate`,  
  `defectStatus`,
  `streetNumber`,
  `streetName`,
  `loNumber`,
]

export const defaultFilterList = [
  `id`,
  `createDate`,
  `creator`,
  `inspector`,
  `route`,
  `defectNumber`,
  `factDate`,  
  `defectStatus`,
  `streetNumber`,
  `streetName`,
  `loNumber`,
]

export const appsConf = (fieldsConfig) => getAppConfigurator(fieldsConfig, typesConf)

export const getColumnsConf = (
  fieldsList = defaultColumnsList,
  fieldsConfig = fieldsConf,
) => createGetColumnsConf(appsConf(fieldsConfig)(fieldApps.COLUMN), fieldsList)

export const getViewConfig = (
  fieldsList = defaultViewList,
  fieldsConfig = fieldsConf,
) => fieldsList.map(appsConf(fieldsConfig)(fieldApps.VIEW))

export const getFilterConfig = (
  fieldsList = defaultFilterList,
  fieldsConfig = fieldsConf,
) => keyBy(fieldsList.map(appsConf(fieldsConfig)(fieldApps.FILTER)), `key`)

export const getFormConfig = (
  fieldsList = defaultFormList,
  fieldsConfig = fieldsConf,
) => keyBy(fieldsList.map(appsConf(fieldsConfig)(fieldApps.FORM)), `key`)

export const getAllColumns = (columnsList, fieldsConfig, metadata) => (
  defaultModuleConfig.routing.list.getAllColumns(defaultColumnsList, fieldsConf, metadata)
)

