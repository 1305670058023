import { schema } from 'normalizr'
import {
  actionCrudCreatorsFor,
  createSelectorForEntityKey,
  createEntitiesSelector,
} from '@infotech/lib-api-middleware'

export const EXPRESS_INSPECTIONS_DAY_SCHEMA_NAME = `dayExpressInspections`
export const URL = `/inspection-day/express-inspection`

export const expressInspectionsDaySchema = new schema.Entity(EXPRESS_INSPECTIONS_DAY_SCHEMA_NAME)

export const expressInspectionsDaySelector = createSelectorForEntityKey(EXPRESS_INSPECTIONS_DAY_SCHEMA_NAME)
export const expressInspectionsDayEntitiesSelector = createEntitiesSelector(EXPRESS_INSPECTIONS_DAY_SCHEMA_NAME)

export const expressInspectionsDayApiActions = actionCrudCreatorsFor(EXPRESS_INSPECTIONS_DAY_SCHEMA_NAME, URL, expressInspectionsDaySchema)