import { defaultProps } from 'recompose'
import createAddressLabel from './createAddressLabel'

export default defaultProps({
  createAddressLabel: createAddressLabel({
    formatLabels: [
      { id: `region` },
      { id: `city` },
      { id: `street` },
      { id: `house` },
    ],
  }),
})
