import { schema } from 'normalizr'
import {
  actionCrudCreatorsFor,
  createSelectorForEntityKey,
  createEntitiesSelector,
} from '@infotech/lib-api-middleware'

export const STREETPARTS_SCHEMA_NAME = `streetParts`
export const URL = `/inspection-night/planned-street-part`

export const streetPartsSchema = new schema.Entity(STREETPARTS_SCHEMA_NAME)

export const streetPartsSelector = createSelectorForEntityKey(STREETPARTS_SCHEMA_NAME)
export const streetPartsEntitiesSelector = createEntitiesSelector(STREETPARTS_SCHEMA_NAME)

export const streetPartsApiActions = actionCrudCreatorsFor(STREETPARTS_SCHEMA_NAME, URL, streetPartsSchema)