import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import join from 'lodash/join'
import map from 'lodash/map'
import get from 'lodash/get'

export const transformEntrance = (value) => `п. ${value}`

export const transformFlat = (value) => `кв. ${value}`

export const defaultFormatLabels = [
  { id: `country` },
  { id: `city` },
  { id: `district` },
  { id: `village` },
  { id: `street` },
  { id: `house` },
  {
    id       : `entrance`,
    transform: transformEntrance,
  },
  {
    id       : `flat`,
    transform: transformFlat,
  },
]

const createAddressLabel = ({ formatLabels = defaultFormatLabels } = {}) => ({
  formattedLabel,
  dividedLabel,
} = {}) => {
  if (!dividedLabel) return formattedLabel
  const dividedArr = compact(map(formatLabels, ({ id, transform }) => {
    const label = get(dividedLabel, id)
    if (!label) return ``
    return transform ? transform(label) : label
  }))
  return isEmpty(dividedArr) ? formattedLabel : join(dividedArr, `, `)
}

export default createAddressLabel
