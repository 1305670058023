import React from 'react'
import Lightbox from 'react-image-lightbox'
import { compose, withHandlers, withState } from 'recompose'

const getImgUrl = (images, photoIndex, isPrev) => {
    if (images.length > 1) {
      const increment = isPrev
        ? images.length - 1
        : 1
      return images[(photoIndex + increment) % images.length].url
    }
    return undefined
}

const enhance = compose(
    withState(`photoIndex`, `setPhotoIndex`, 0),
    withState(`isOpen`, `toggleOpen`, false),
    withHandlers({
        handleOpen: ({ setPhotoIndex, toggleOpen }) => (index) => {
            setPhotoIndex(index)
            toggleOpen(true)
        },
    }),
)

const Gallery = (
    {
      images,
      photoIndex,
      isOpen,
      toggleOpen,
      setPhotoIndex,
      handleOpen,
    },
  ) => (
    <div className="">
        {images?.map((a, i) =><div className={a.className}>
            <a href={a.url}><i className="fa fa-cloud-download" /></a>
            &nbsp;
            { /* eslint-disable-next-line */ }
            <a
                role="button"
                tabindex="0"
                onClick={() => handleOpen(i)}
            >
                {a.name}
            </a>
        </div>)}
        {isOpen && (
            <Lightbox
                mainSrc={images[photoIndex].url}
                nextSrc={getImgUrl(images, photoIndex, false)}
                prevSrc={getImgUrl(images, photoIndex, true)}
                onCloseRequest={() => toggleOpen(false)}
                onMovePrevRequest={() => setPhotoIndex(((photoIndex + images.length) - 1) % images.length)}
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                reactModalStyle={{ overlay: { zIndex: 2000 } }}
            />
        )
        }
    </div>
  )
  
  export default enhance(Gallery)