import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import {
  nest,
  withProps,
  withPropsOnChange,
  compose,
} from 'recompose'
import { Combobox } from 'react-widgets'
import { connect } from 'react-redux'
import keyBy from 'lodash/keyBy'
import values from 'lodash/values'
import sortBy from 'lodash/sortBy'

import { mapissuesModule } from '@infotech/4sides-front-core'
import { fieldsConf as mapissuesFieldsConf } from '@infotech/mapissues'
import { getEntity, getEntities } from '@infotech/lib-api-middleware'
import {
  fieldApps,
  fieldTypes,
  typesConf,
  HEADING,
  BODY,
  getAppConfigurator,
} from '@infotech/view-config'
import {
  Text,
  WithTitle,
  DateTimePickerField,
  DateFormatter,
  InputHelper,
  DateRange,
  EntityLink,
  formFieldAdapter,
  withDebounce,
  Multiselect,
  BooleanFormatter,
  DropdownList,
} from '@infotech/base-components'
import UIKitInput from '@infotech/uikit-input'
import Checkbox from '@infotech/uikit-checkbox'
import { createGetColumnsConf } from '@infotech/crud'
import {
  dateRangeFilters,
  stringListFilters,
  stringListAnyFilters,
  numberFilters,
  textSearchFilters,
  booleanFilters,
} from '@infotech/filters'

import { SG_REFERENCE_HOST } from 'api/constants'
import { CRUD_PATH } from './constants'
import { 
  CRUD_PATH as CRUD_PATH_DAY,
  CRUD_PATH_PLURAL as CRUD_PATH_DAY_PLURAL,
} from '../StreetPartDayModule/constants'
import { 
  CRUD_PATH as CRUD_PATH_EXPRESS_INSPECTION_DAY,
  CRUD_PATH_PLURAL as CRUD_PATH_EXPRESS_INSPECTION_DAY_PLURAL,
} from '../ExpressInspectionsDayModule/constants'

const { defaultModuleConfig } = mapissuesModule.config

export const ifDayEntity = (pathname) => {
  return pathname.startsWith(CRUD_PATH_DAY, 1) 
    || pathname.startsWith(CRUD_PATH_DAY_PLURAL, 1)
    || pathname.startsWith(CRUD_PATH_EXPRESS_INSPECTION_DAY, 1)
    || pathname.startsWith(CRUD_PATH_EXPRESS_INSPECTION_DAY_PLURAL, 1)
}

export const generateFullName = ({ lastName, firstName, patronymic }) => (
  [lastName, firstName, patronymic].filter((v) => !!v).join(` `)
)

export const DatePickerField = (props) => <DateTimePickerField withoutTime={true} {...props} />

export const DateFormatterNoTime = (props) => <DateFormatter format="DD.MM.YYYY"  emptyData="—" {...props} />

export const DebouncedUIKitInput = formFieldAdapter(nest(InputHelper, withDebounce(UIKitInput)))

const empoyeesEnhancer = compose(
  connect((state) => ({
    employees: getEntities(state, `employee`),
  })),
  withRouter,
  withPropsOnChange([`employees`, `location`], ({ employees, location: { pathname } }) => ({
    data      : values(employees).filter((e) => e[ifDayEntity(pathname) ? `day` : `night`]).map((e) => ({...e, fullName: generateFullName(e)})),
    textField: `fullName`,
    valueField: `code`,
    filter: `contains`,
  })),
)

export const EmployeeField = compose(
  empoyeesEnhancer,
  withProps(({ input: { onChange, value } }) => ({
    onSelect: val => onChange(val.code),
    defaultValue: value,
  })),
)(Combobox)

export const EmployeeFilter = empoyeesEnhancer(Multiselect)

const PeriodicityFilter = compose(
  connect((state) => ({
    dict: getEntities(state, `lo_inspection_periodicity`),
  })),
  withRouter,
  withPropsOnChange([`dict`, `location`], ({ dict, location: { pathname } }) => ({
    data: values(dict).filter((e) => {
      // key should be 'day' or 'night'
      const shift = e.shift_type?.link?.key 
      if(!shift) {
        return true
      }
      if((shift === `day`) && ifDayEntity(pathname)) {
        return true
      }
      if((shift === `night`) && !ifDayEntity(pathname)) {
        return true
      }
      return false
    }),
    textField: `name`,
    valueField: `code`,
    filter: `contains`,
  })),
)(Multiselect)

const DefectLabelsFilter = compose(
  connect((state) => ({
    defectLabels: getEntities(state, `defect_labels`),
  })),
  withRouter,
  withPropsOnChange([`defectLabels`, `location`], ({ defectLabels, location: { pathname } }) => ({
    data      : sortBy(values(defectLabels), [`$id$`]).filter((e) => e[ifDayEntity(pathname) ? `day` : `night`]),
    textField: `name`,
    valueField: `code`,
    filter: `contains`,
  })),
)(Multiselect)

const DictionaryFilterGenerator = (entityName, { valueField = `code`, textField = `name` } = {}) => connect((state) => ({
    data      : values(getEntities(state, entityName)),
    textField,
    valueField,
    filter: `contains`,
  }),
)(Multiselect)

const IdFormatter = compose(
  withRouter,
  withPropsOnChange([`location`], ({ location: { pathname } }) => ({
    entity: ifDayEntity(pathname) ? CRUD_PATH_DAY : CRUD_PATH,
  })),
)(EntityLink)

const getDistrictCodeByOkato = ({ code }) => code

export const ReferenceEntityViewGenerator = (entityName, textGenerator) => connect(
  (state, { data, value = data }) => {
    if(!value) {
      return { resolved: `—` }
    }
    const entity = getEntity(state, entityName, value)
    if(entity && textGenerator) {
      return { resolved: textGenerator(entity) }
    }
    return { resolved: entity?.name ||  `—` }
  }
)(({resolved}) => resolved)

const TagsView = connect(
  (state, { data, value = data }) => {
    if(!value || !value.length) {
      return { resolved: `—` }
    }
    return { resolved: value.map(v => getEntity(state, `tag`, v)?.name).filter(v => !!v).join(`; `) }
  }
)(({resolved}) => resolved)

export const ReferenceLink = ({ data, value = data }) => (
  <a 
    target="_blank"
    rel="noopener noreferrer"
    href={`${SG_REFERENCE_HOST}/integration/view/open/dv/sg_street_part?key=${value}`}
  >{value}</a>
)

export const IssueLink = ({ data, value = data }) => (
  <Link to={`/issue/read/${value}`}>{value}</Link>
)

const BooleanDropdown = compose(
  withProps(({
    data      : [
      {
        value: true,
        text : `Да`,
      },
      {
        value: false,
        text : `Нет`,
      },
    ],
    valueField: `value`,
    textField : `text`,
  })),
)(DropdownList)

const statuses = {
  CREATED: `Создан`,
  IN_WORK: `В работе`,
  COMPLETE: `Завершен`,
  ARCHIVE: `В архиве`,
}

const StatusView = ({ data, value = data, dependentValues, allData = dependentValues }) => (
  <span className="status-formatter">
    {allData?.overdue && <span className="status" style={{backgroundColor: `red`}} />}{statuses[value]}
  </span>
)

const StatusFilter = compose(
  withProps(({
    data: Object.keys(statuses).filter(k => (k !== `ARCHIVE`)).map(k => ({ 
      value: k,
      text: statuses[k],
    })),
    valueField: `value`,
    textField : `text`,
  })),
)(Multiselect)

const NoInspectorCheckbox = compose(
  withProps(({ input: { value, onChange } }) => ({
    value,
    onChange,
  })),
  withProps(
    ({ onChange, value }) => ({
      checked : value === `` ? false : true,
      onChange: () => {
        onChange(value === `` ? false : ``)
      },
    }),
  ),
)(Checkbox)

export const fieldsConf = {
  id: {
    type: fieldTypes.TEXT,
    name: `Id`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Id`,
        formatter: IdFormatter,
        sortable : true,
        width    : 60,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, Text),
        title    : `Id`,
      },
      [fieldApps.FILTER]: {
        title        : `Id`,
        component    : DebouncedUIKitInput,
        parseValue   : numberFilters.parseValue,
        valueToFilter: numberFilters.valueToFilter,
      },
    },
  },
  inventoryNumber: {
    type: fieldTypes.TEXT,
    name: `Инв. №`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Инв. №`,
        sortable : true,
        formatter: ReferenceLink,
        width    : 80,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceLink),
        title    : `Инв. №`,
      },
      [fieldApps.FILTER]: {
        title        : `Инв. №`,
        component    : DebouncedUIKitInput,
        parseValue   : numberFilters.parseValue,
        valueToFilter: numberFilters.valueToFilter,
      },
    },
  },
  status: {
    type: fieldTypes.TEXT,
    name: `Статус`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Статус`,
        sortable : true,
        formatter: StatusView,
        width    : 100,
        getRowMetaData: (row) => row,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, StatusView),
        title    : `Статус`,
      },
      [fieldApps.FILTER]: {
        title        : `Статус`,
        component    : StatusFilter,
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
    },
  },
  overdue: {
    type: fieldTypes.TEXT,
    name: `Просроченный`,
    apps: {
      [fieldApps.FILTER]: {
        title        : `Просроченный`,
        component    : BooleanDropdown,
        parseValue   : booleanFilters.parseValue,
        valueToFilter: booleanFilters.valueToFilter,
      },
    },
  },
  objectClass: {
    type: fieldTypes.TEXT,
    name: `Класс объектов`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Класс объектов`,
        formatter: ReferenceEntityViewGenerator(`lo_class`),
        sortable : true,
        width    : 130,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceEntityViewGenerator(`lo_class`)),
        title    : `Класс объектов`,
      },
      [fieldApps.FILTER]: {
        title        : `Класс объектов`,
        component    : DictionaryFilterGenerator(`lo_class`),
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
    },
  },
  streetName: {
    type: fieldTypes.TEXT,
    name: `Наименование`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Наименование`,
        sortable : true,
        width    : 180,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, Text),
        title    : `Наименование`,
      },
      [fieldApps.FILTER]: {
        title        : `Наименование`,
        component    : DebouncedUIKitInput,
        parseValue   : textSearchFilters.parseValue,
        valueToFilter: textSearchFilters.valueToFilter,
      },
    },
  },
  landmark: {
    type: fieldTypes.TEXT,
    name: `Ориентир`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Ориентир`,
        sortable : true,
        width    : 180,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, Text),
        title    : `Ориентир`,
      },
      [fieldApps.FILTER]: {
        title        : `Ориентир`,
        component    : DebouncedUIKitInput,
        parseValue   : textSearchFilters.parseValue,
        valueToFilter: textSearchFilters.valueToFilter,
      },
    },
  },
  periodicity: {
    type: fieldTypes.TEXT,
    name: `Расписание`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Расписание`,
        formatter: ReferenceEntityViewGenerator(`lo_inspection_periodicity`),
        sortable : true,
        width    : 110,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceEntityViewGenerator(`lo_inspection_periodicity`)),
        title    : `Расписание`,
      },
      [fieldApps.FILTER]: {
        title        : `Расписание`,
        component    : PeriodicityFilter,
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
    },
  },  
  inspectorPresent: {
    type: fieldTypes.TEXT,
    name: `Инспектор отсутствует`,
    apps: {
      [fieldApps.FILTER]: {
        title        : `Инспектор отсутствует`,
        component    : NoInspectorCheckbox,
        parseValue   : booleanFilters.parseValue,
        valueToFilter: booleanFilters.valueToFilter,
      },
    },
  },
  inspector: {
    type: fieldTypes.TEXT,
    name: `Инспектор`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Инспектор`,
        formatter: ReferenceEntityViewGenerator(`employee`, generateFullName),
        sortable : true,
        width    : 200,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceEntityViewGenerator(`employee`, generateFullName)),
        title    : `Инспектор`,
      },
      [fieldApps.FILTER]: {
        title        : `Инспектор`,
        component    : EmployeeFilter,
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
      [fieldApps.FORM]: {
        place    : BODY,
        title    : `Инспектор`,
        component: nest(InputHelper, EmployeeField),
      },
    },
  },
  limitDate: {
    type: fieldTypes.TEXT,
    name: `Предельная дата`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Предельная дата`,
        formatter: DateFormatterNoTime,
        sortable : true,
        width    : 125,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, DateFormatterNoTime),
        title    : `Предельная дата`,
      },
      [fieldApps.FILTER]: {
        title        : `Предельная дата`,
        component    : DateRange,
        parseValue   : dateRangeFilters.parseValue,
        valueToFilter: dateRangeFilters.valueToFilter,
      },
    },
  },
  plannedDate: {
    type: fieldTypes.TEXT,
    name: `Плановая дата`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Плановая дата`,
        formatter: DateFormatterNoTime,
        sortable : true,
        width    : 110,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, DateFormatterNoTime),
        title    : `Плановая дата`,
      },
      [fieldApps.FILTER]: {
        title        : `Плановая дата`,
        component    : DateRange,
        parseValue   : dateRangeFilters.parseValue,
        valueToFilter: dateRangeFilters.valueToFilter,
      },
      [fieldApps.FORM]: {
        place    : BODY,
        title    : `Плановая дата`,
        component: nest(InputHelper, DatePickerField),
      },
    },
  },
  factDate: {
    type: fieldTypes.TEXT,
    name: `Дата завершения`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Дата завершения`,
        formatter: DateFormatterNoTime,
        sortable : true,
        width    : 130,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, DateFormatterNoTime),
        title    : `Дата завершения`,
      },
      [fieldApps.FILTER]: {
        title        : `Дата завершения`,
        component    : DateRange,
        parseValue   : dateRangeFilters.parseValue,
        valueToFilter: dateRangeFilters.valueToFilter,
      },
    },
  },
  startDate: {
    type: fieldTypes.TEXT,
    name: `Дата начала`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Дата начала`,
        formatter: DateFormatterNoTime,
        sortable : true,
        width    : 100,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, DateFormatterNoTime),
        title    : `Дата начала`,
      },
      [fieldApps.FILTER]: {
        title        : `Дата начала`,
        component    : DateRange,
        parseValue   : dateRangeFilters.parseValue,
        valueToFilter: dateRangeFilters.valueToFilter,
      },
    },
  },
  electricalNetwork: {
    type: fieldTypes.TEXT,
    name: `РЭС`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `РЭС`,
        sortable : true,
        width    : 70,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, Text),
        title    : `РЭС`,
      },
      [fieldApps.FILTER]: {
        title        : `РЭС`,
        component    : DictionaryFilterGenerator(`electrical_network`, { valueField: `name` }),
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
    },
  },
  district: {
    type: fieldTypes.TEXT,
    name: `Округ`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Округ`,
        formatter: ReferenceEntityViewGenerator(`district`, getDistrictCodeByOkato),
        sortable : true,
        width    : 80,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceEntityViewGenerator(`district`, getDistrictCodeByOkato)),
        title    : `Округ`,
      },
      [fieldApps.FILTER]: {
        title        : `Округ`,
        component    : DictionaryFilterGenerator(`district`, { valueField: `okato`, textField: `code` }),
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
    },
  },
  borough: {
    type: fieldTypes.TEXT,
    name: `Район`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Район`,
        formatter: ReferenceEntityViewGenerator(`borough`),
        sortable : true,
        width    : 90,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceEntityViewGenerator(`borough`)),
        title    : `Район`,
      },
      [fieldApps.FILTER]: {
        title        : `Район`,
        component    : DictionaryFilterGenerator(`borough`, { valueField: `okato`, textField: `name` }),
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
    },
  },
  tags: {
    type: fieldTypes.TEXT,
    name: `Теги`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Теги`,
        formatter: TagsView,
        sortable : true,
        width    : 120,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, TagsView),
        title    : `Теги`,
      },
      [fieldApps.FILTER]: {
        title        : `Теги`,
        component    : DictionaryFilterGenerator(`tag`),
        parseValue   : stringListAnyFilters.parseValue,
        valueToFilter: stringListAnyFilters.valueToFilter,
      },
    },
  },
  defectLabels: {
    type: fieldTypes.TEXT,
    name: `Дефекты`,
    apps: {
      [fieldApps.FILTER]: {
        title        : `Дефекты`,
        component    : DefectLabelsFilter,
        parseValue   : stringListAnyFilters.parseValue,
        valueToFilter: stringListAnyFilters.valueToFilter,
      },
    },
  },
  category: {
    type: fieldTypes.TEXT,
    name: `Категория`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Категория`,
        sortable : true,
        width    : 80,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, Text),
        title    : `Категория`,
      },
      [fieldApps.FILTER]: {
        title        : `Категория`,
        component    : DictionaryFilterGenerator(`category`, { textField: `code` }),
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
    },
  },
  route: {
    type: fieldTypes.TEXT,
    name: `Наряд-задание`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Наряд-задание`,
        formatter: IssueLink,
        sortable : true,
        width    : 110,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, IssueLink),
        title    : `Наряд-задание`,
      },
      [fieldApps.FILTER]: {
        title        : `Наряд-задание`,
        component    : DebouncedUIKitInput,
        parseValue   : textSearchFilters.parseValue,
        valueToFilter: textSearchFilters.valueToFilter,
      },
    },
  },
  lightingObjectCount: {
    type: fieldTypes.TEXT,
    name: `Кол-во объектов`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Кол-во объектов`,
        sortable : true,
        width    : 120,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ({ data }) => <span>{data || 0}</span>),
        title    : `Кол-во объектов`,
      },
    },
  },
  archived: {
    type: fieldTypes.TEXT,
    name: `В архиве`,
    apps: {
      [fieldApps.FILTER]: {
        ...mapissuesFieldsConf.archived.apps[fieldApps.FILTER],
        title: `Архивные участки`,
      },
    },
  },
  ahpCategory: {
    type: fieldTypes.TEXT,
    name: `Категория АХП`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Категория АХП`,
        sortable : true,
        width    : 110,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, Text),
        title    : `Категория АХП`,
      },
      [fieldApps.FILTER]: {
        title        : `Категория АХП`,
        component    : DebouncedUIKitInput,
        parseValue   : textSearchFilters.parseValue,
        valueToFilter: textSearchFilters.valueToFilter,
      },
    },
  },
  outboundMagistral: {
    type: fieldTypes.TEXT,
    name: `Вылетные магистрали`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Вылетные магистрали`,
        formatter: BooleanFormatter,
        sortable : true,
        width    : 140,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, BooleanFormatter),
        title    : `Вылетные магистрали`,
      },
      [fieldApps.FILTER]: {
        title        : `Вылетные магистрали`,
        component    : BooleanDropdown,
        parseValue   : booleanFilters.parseValue,
        valueToFilter: booleanFilters.valueToFilter,
      },
    },
  },
  streetPartType: {
    type: fieldTypes.TEXT,
    name: `Тип участка`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Тип участка`,
        formatter: ReferenceEntityViewGenerator(`street_part_type`),
        sortable : true,
        width    : 150,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceEntityViewGenerator(`street_part_type`)),
        title    : `Тип участка`,
      },
      [fieldApps.FILTER]: {
        title        : `Тип участка`,
        component    : DictionaryFilterGenerator(`street_part_type`),
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
    },
  },
  streetPartSubtype: {
    type: fieldTypes.TEXT,
    name: `Подтип участка`,
    apps: {
      [fieldApps.COLUMN]: {
        name     : `Подтип участка`,
        formatter: ReferenceEntityViewGenerator(`street_part_subtype`),
        sortable : true,
        width    : 150,
      },
      [fieldApps.VIEW]: {
        place    : HEADING,
        component: nest(WithTitle, ReferenceEntityViewGenerator(`street_part_subtype`)),
        title    : `Подтип участка`,
      },
      [fieldApps.FILTER]: {
        title        : `Подтип участка`,
        component    : DictionaryFilterGenerator(`street_part_subtype`),
        parseValue   : stringListFilters.parseValue,
        valueToFilter: stringListFilters.valueToFilter,
      },
    },
  },
}

export const defaultColumnsList = [
  `id`,
  `inventoryNumber`,
  `status`,
  `objectClass`,
  `streetName`,
  `landmark`,
  `periodicity`,
  `inspector`,
  `plannedDate`,
  `limitDate`,
  `startDate`,
  `factDate`,  
  `electricalNetwork`,
  `district`,
  `borough`,
  `tags`,
  `category`,
  `ahpCategory`,
  `outboundMagistral`,
  `route`,
  `lightingObjectCount`,
  `streetPartType`,
  `streetPartSubtype`,
]

export const defaultFormList = [

]

export const defaultFormListEdit = [
  `inspector`,
  `plannedDate`,
]

export const defaultViewList = [
  `inventoryNumber`,
  `status`,
  `objectClass`,
  `streetName`,
  `landmark`,
  `periodicity`,
  `inspector`,
  `plannedDate`,
  `limitDate`,
  `startDate`,
  `factDate`, 
  `electricalNetwork`,
  `district`,
  `borough`,
  `tags`,
  `category`,
  `ahpCategory`,
  `outboundMagistral`,
  `route`,
  `lightingObjectCount`,
  `streetPartType`,
  `streetPartSubtype`,
]

export const defaultFilterList = [
  `id`,
  `inventoryNumber`,
  `status`,  
  `objectClass`,
  `streetName`,
  `landmark`,
  `periodicity`,
  `inspector`,
  `inspectorPresent`,
  `plannedDate`,
  `limitDate`,
  `startDate`,
  `factDate`, 
  `overdue`,
  `electricalNetwork`,
  `district`,
  `borough`,
  `tags`,
  `category`,
  `ahpCategory`,
  `outboundMagistral`,
  `route`,
  `defectLabels`,
  `streetPartType`,
  `streetPartSubtype`,
  `archived`,
]

export const appsConf = (fieldsConfig) => getAppConfigurator(fieldsConfig, typesConf)

export const getColumnsConf = (
  fieldsList = defaultColumnsList,
  fieldsConfig = fieldsConf,
) => createGetColumnsConf(appsConf(fieldsConfig)(fieldApps.COLUMN), fieldsList)

export const getViewConfig = (
  fieldsList = defaultViewList,
  fieldsConfig = fieldsConf,
) => fieldsList.map(appsConf(fieldsConfig)(fieldApps.VIEW))

export const getFilterConfig = (
  fieldsList = defaultFilterList,
  fieldsConfig = fieldsConf,
) => keyBy(fieldsList.map(appsConf(fieldsConfig)(fieldApps.FILTER)), `key`)

export const getFormConfig = (
  fieldsList = defaultFormList,
  fieldsConfig = fieldsConf,
) => keyBy(fieldsList.map(appsConf(fieldsConfig)(fieldApps.FORM)), `key`)

export const getAllColumns = (columnsList, fieldsConfig, metadata) => (
  defaultModuleConfig.routing.list.getAllColumns(defaultColumnsList, fieldsConf, metadata)
)

