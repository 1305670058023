import { WithTooltip } from '@infotech/base-components'
import { CRUD_PATH_PLURAL } from './constants'

import { ReactComponent as Moon } from "./moon.svg"

const Icon = WithTooltip({
  placement      : `right`,
  tooltip        : `Плановые участки`,
  TargetComponent: Moon,
})

export default {
  id  : `streetParts`,
  name: `Плановые участки (ночные)`,
  to  : `/${CRUD_PATH_PLURAL}`,
  icon: Icon,
  hide: (capabilities) => !capabilities[`sg.street-parts.night.access`],
}
