export const STREETPART_DEFECTS = `STREETPART_DEFECTS`
export const STREETPART_SELECTED_OBJECT = `STREETPART_SELECTED_OBJECT`

const STREETPART_DEFECTS_NEW = `STREETPART_DEFECTS_NEW`
const STREETPART_SELECT_OBJECT = `STREETPART_SELECT_OBJECT`

export const newDefects = (streetPartId, defects) => ({
    defects,
    streetPartId,
    type: STREETPART_DEFECTS_NEW,
})

export const selectObject = (streetPartId, object) => ({
    object,
    streetPartId,
    type: STREETPART_SELECT_OBJECT,
})

const defectsReducer = (state = {}, action) => {
    if (action.type !== STREETPART_DEFECTS_NEW) {
        return state
    }
    const { streetPartId, defects } = action
    return { ...state, [streetPartId]: defects }
}

const selectObjectReducer = (state = {}, action) => {
    if (action.type !== STREETPART_SELECT_OBJECT) {
        return state
    }
    const { streetPartId, object } = action
    return { ...state, [streetPartId]: object }
}

export default {
    [STREETPART_DEFECTS]: defectsReducer,
    [STREETPART_SELECTED_OBJECT]: selectObjectReducer,
}