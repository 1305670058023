import React from 'react'
import { withRouter } from 'react-router'
import { Form, reduxForm, } from 'redux-form'
import {
  defaultProps, compose, nest, withState,
} from 'recompose'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { addNotification as notify } from 'reapop'

import { FeatureChecker } from '@infotech/featureList'
import { selectQueryForCRUDlist } from '@infotech/crud'
import { createNotifyConf } from '@infotech/notify'
import { CALL_API } from '@infotech/lib-api-middleware'
import {
  Modal,
  modalEnhancer,
  FieldWithTitle, 
  InputHelper,
  Spinner,
  CheckboxAdapter,
} from '@infotech/base-components'
import { EditIcon } from '@infotech/uikit-icons'
import { PrimaryButton, Button } from '@infotech/uikit-button'
import BaseCheckbox from '@infotech/uikit-checkbox'

import { URL as URL_NIGHT } from 'api/streetParts'
import { URL as URL_DAY } from 'api/streetPartsDay'

import { EmployeeField, DatePickerField, ifDayEntity } from '../../fieldsConf'

const STREET_PART_MASS_CHANGE_FORM = `STREET_PART_MASS_CHANGE_FORM`

const EmployeeSelect = nest(InputHelper, EmployeeField)

const DatePicker = nest(InputHelper, DatePickerField)

const Checkbox = CheckboxAdapter(BaseCheckbox)

const iconStyle = {
  fill: `#1183E7`,
}

const FormButtons = ({ toggle }) => (
  <React.Fragment>
    <PrimaryButton
      type="submit"
    >
      Редактировать
    </PrimaryButton>
    <Button
      type="button"
      onClick={toggle}
      withBorder
    >
      Отменить
    </Button>
  </React.Fragment>
)

const MassChangeButton = connect(null, (dispatch, {
  filter = ``,
  onToggle,
  setIssuesCount,
  pathname,
}) => ({
  onClick: () => {
    setIssuesCount(null)

    if (filter.includes(`archived==true`)) {
      dispatch(notify(createNotifyConf(`Архивные участки редактировать нельзя`, `error`)))
      return
    }


    const request = {
      type      : `MASS_CHANGE_PREVALIDATION`,
      [CALL_API]: {
        url: `${ifDayEntity(pathname) ? URL_DAY : URL_NIGHT}`,
        query: {
          query: filter.includes(`archived==false`) ? filter : `${filter}${filter.length ? `;` : ``}archived==false`,
          limit: 0,
        },
      },
    }

    dispatch(request).then((response) => {
      if (response.error) {
        dispatch(notify(createNotifyConf(response.error.exception || `Ошибка превалидации`, `error`)))
        return
      }
      const count = get(response, `response.headers.total-count`)
      setIssuesCount(count)
      onToggle()
    })
  },
}))(
  ({
    onClick,
  }) => (
    <div className="dropdown">
      <button
        type="button"
        className="action list"
        title="Массовое редактирование"
        onClick={onClick}
      >
        <EditIcon size="medium" style={iconStyle} />
      </button>
    </div>
  )
)

const onSubmit = (
  values,
  dispatch,
  {
    toggle, filter, pathname, fetchEntities,
  }
) => {
  const inspector = get(values, `inspector`)
  const plannedDate = get(values, `plannedDate`)
  const complete = get(values, `complete`)
  const incomplete = get(values, `incomplete`)

  if (!inspector && !plannedDate && !complete && !incomplete) {
    dispatch(notify(createNotifyConf(`Ошибка: хотя бы одно из полей должно быть заполнено`, `error`)))
    return
  }

  if (complete && incomplete) {
    dispatch(notify(createNotifyConf(`Ошибка: нельзя одновременно закрывать и возвращать в работу участки`, `error`)))
    return
  }

  const request = {
    type      : `STREET_PART_MASS_CHANGE`,
    [CALL_API]: {
      url    : `${ifDayEntity(pathname) ? URL_DAY : URL_NIGHT}/batch`,
      query: {
        query: filter,
      },
      options: {
        method: `PUT`,        
        body: {
          inspector,
          plannedDate,
          complete,
          incomplete,
        },
      },
    },
  }

  dispatch(request).then((response) => {
    if (response.error) {
      dispatch(notify(createNotifyConf(response.error.exception || `Ошибка`, `error`)))
      return
    }
    const count = get(response, `response.json`)
    dispatch(notify(createNotifyConf(`Редактирование успешно, участков обработано: ${count}`, `success`)))
    fetchEntities()
    toggle()
  })
  
}

const MassChangeWrapperComponent = compose(
  reduxForm({
    form: STREET_PART_MASS_CHANGE_FORM,
    onSubmit,
  }),
)(({ handleSubmit, children }) => (
  <Form onSubmit={handleSubmit}>
    {children}
  </Form>
))

const massChangeModalEnhancer = defaultProps({
  headerComponent : () => `Массовое редактирование`,
  toggleComponent : MassChangeButton,
  wrapperComponent: MassChangeWrapperComponent,
  footerComponent: FormButtons,
  isFullHeight   : false,
})

const ModalEnhanced = compose(
  massChangeModalEnhancer,
  modalEnhancer,
)(Modal)

const massChangeEnhancer = compose(
  defaultProps({
    featureToCheck: `sg.general-edit.access`,
  }),
  FeatureChecker,
  connect((state, {
    config, componentConfig: { getFilter },
  }) => ({
    filter: getFilter(state, config),
    query: selectQueryForCRUDlist(state, config.entityName),
  })),
  connect(null, (dispatch, { query, config: { apiActions: { fetch } } }) => ({
    fetchEntities: () => dispatch(fetch(query)),
  })),
  withRouter,
  withState(`issuesCount`, `setIssuesCount`, null),
)

const MassChange = ({
  filter,
  location: { pathname },
  fetchEntities,
  setIssuesCount,
  issuesCount,
}) => (
  <ModalEnhanced
    filter={filter}
    pathname={pathname}
    fetchEntities={fetchEntities}
    setIssuesCount={setIssuesCount}
  >
    {(issuesCount !== null) ? <div>
      Выбрано участков для редактирования: <b 
        className={(issuesCount >= 100) ? `text-danger` : ``}
        style={{ fontSize: `16px` }}
      >{issuesCount}</b>
    </div> : <div><Spinner/></div>}
    <br/>
    <FieldWithTitle
      component={EmployeeSelect}
      title="Инспектор"
      formName={STREET_PART_MASS_CHANGE_FORM}
      name="inspector"
    />
    <FieldWithTitle
      component={DatePicker}
      title="Плановая дата"
      formName={STREET_PART_MASS_CHANGE_FORM}
      name="plannedDate"
    />
    <FieldWithTitle
      component={Checkbox}
      name="complete"
      formName={STREET_PART_MASS_CHANGE_FORM}
      title="Закрыть участки"
    />
    <FieldWithTitle
      component={Checkbox}
      name="incomplete"
      formName={STREET_PART_MASS_CHANGE_FORM}
      title="Вернуть участки в работу"
    />
  </ModalEnhanced>
)

export default massChangeEnhancer(MassChange)
