import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    compose,
    withPropsOnChange,
    branch,
    renderComponent,
    withState,
} from 'recompose'
import { addNotification as notify } from 'reapop'
import isEmpty from 'lodash/isEmpty'
import { ApiTransport, omitApiTransportProps } from '@infotech/lib-api-middleware'
import { WidgetTable, DateFormatter } from '@infotech/base-components'
import { fieldApps } from '@infotech/view-config'
import { CALL_API } from '@infotech/lib-api-middleware'
import { createNotifyConf } from '@infotech/notify'

import {
    streetPartsApiActions,
    STREETPARTS_SCHEMA_NAME,
    streetPartsSelector,
} from 'api/streetParts'
import {
    streetPartsDayApiActions,
    STREETPARTS_DAY_SCHEMA_NAME,
    streetPartsDaySelector,
} from 'api/streetPartsDay'
import { CRUD_PATH as CRUD_PATH_NIGHT } from 'modules/StreetPartModule/constants'
import { CRUD_PATH as CRUD_PATH_DAY } from 'modules/StreetPartDayModule/constants'
import { fieldsConf } from 'modules/StreetPartModule/fieldsConf'
import { URL as URL_NIGHT } from 'api/streetParts'
import { URL as URL_DAY } from 'api/streetPartsDay'
import { editEnhancer } from 'modules/StreetPartModule/CRUDConfig'
import './streetPartWidget.scss'

const columns = [ {
    HeaderView    : () => `Id`,
    width         : `70px`,
    ColumnView    : ({ rowData: { id }, ifDayShift }) => <div><Link to={`/${ifDayShift ? CRUD_PATH_DAY : CRUD_PATH_NIGHT}/read/${id}`}>
        {id}
    </Link></div>,
  }, {
    HeaderView    : () => `Инв. №`,
    width         : `100px`,
    ColumnView    : ({ rowData: { inventoryNumber } }) => <div>
      {fieldsConf.inventoryNumber.apps[fieldApps.COLUMN].formatter({value: inventoryNumber})}
    </div>,
  }, {
    HeaderView    : () => `Статус`,
    width         : `120px`,
    ColumnView    : ({ rowData: { status, overdue } }) => <div>
      {fieldsConf.status.apps[fieldApps.COLUMN].formatter({value: status, allData: { overdue }})}
    </div>,
  },  {
    HeaderView    : () => `Класс объектов`,
    width         : `130px`,
    ColumnView    : ({ rowData: { objectClass } }) => {
      const Formatter = fieldsConf.objectClass.apps[fieldApps.COLUMN].formatter
      return <div><Formatter value={objectClass} /></div>
    },
  }, {
    HeaderView    : () => `Наименование`,
    width         : `180px`,
    ColumnView    : ({ rowData: { streetName } }) => <div>{streetName}</div>,
  }, {
    HeaderView    : () => `Ориентир`,
    width         : `180px`,
    ColumnView    : ({ rowData: { landmark } }) => <div>{landmark}</div>,
  }, {
    HeaderView    : () => `Расписание`,
    width         : `130px`,
    ColumnView    : ({ rowData: { periodicity } }) => {
      const Formatter = fieldsConf.periodicity.apps[fieldApps.COLUMN].formatter
      return <div><Formatter value={periodicity} /></div>
    },
  }, {
    HeaderView    : () => `Дата начала`,
    width         : `150px`,
    ColumnView    : ({ rowData: { startDate } }) => <div><DateFormatter value={startDate} emptyData="—"/></div>,
  }, {
    HeaderView    : () => `Дата завершения`,
    width         : `150px`,
    ColumnView    : ({ rowData: { factDate } }) => <div><DateFormatter value={factDate} emptyData="—"/></div>,
  }, {
    HeaderView    : () => ``,
    width         : `150px`,
    ColumnView    : editEnhancer(({ rowData: { startDate, factDate, id, archived }, openStreetpart, closeStreetpart, reopenStreetpart }) => {
      if (archived) {
        return <div />
      } 
      let text, onClick
      if (factDate) {
        text = `Вернуть в работу`
        onClick = () => reopenStreetpart(id)
      } else if (startDate) {
        text = `Закрыть`
        onClick = () => closeStreetpart(id)
      } else {
        text = `Взять в работу`
        onClick = () => openStreetpart(id)
      }
      return <div className="streetpart-widget-table-hidden">
        <button className="btn btn-sm btn-secondary" onClick={onClick}>{text}</button>
      </div>
    })
  },
]

const NoData = () => <div className="streetmap-nodefects">Нет данных.</div>

const enhancer = compose(
  branch(
    ({ data }) => isEmpty(data?.streetParts),
    renderComponent(NoData)
  ),
  withState(`reloadCounter`, `setReloadCounter`, 0),
  withPropsOnChange(
      [`data`],
      ({ data: { type: { key } } }) => ({
          ifDayShift: (key === `sg_shift_day`)
      })
  ),
  withPropsOnChange(
    [`data`, `reloadCounter`],
    ({ data: { streetParts }, ifDayShift, reloadCounter }) => ({
      entity      : ifDayShift ? STREETPARTS_DAY_SCHEMA_NAME : STREETPARTS_SCHEMA_NAME,
      apiAction   : ifDayShift ? streetPartsDayApiActions.fetch : streetPartsApiActions.fetch,
      dataSelector: ifDayShift ? streetPartsDaySelector : streetPartsSelector,
      query       : {
        query: `id=in=(${streetParts.join(`,`)})`,
        limit: `-1`,
      },
      dataPropName: `streetParts`,
      observableProps: reloadCounter,
    })
  ),
  ApiTransport,
  omitApiTransportProps,
  withPropsOnChange(
    [`streetParts`],
    ({ streetParts }) => {
      if (isEmpty(streetParts)) {
        return
      }
      const statusesSequence = {
        CREATED: 1,
        IN_WORK: 2,
        COMPLETE: 3,
        ARCHIVE: 4,
      }
      return {
        streetParts: [...streetParts].sort((a, b) => (statusesSequence[a.status] - statusesSequence[b.status]))
      } 
    }
  ),
  connect(null, (dispatch, { ifDayShift, setReloadCounter }) => {
    const sendRequest = (request) => {
      dispatch(request).then((response) => {
        if(response.error) {
            dispatch(notify(createNotifyConf(`Ошибка`, `error`)))
            return
        }
        dispatch(notify(createNotifyConf(`Успешно`, `success`)))
        setReloadCounter(Math.random())
      })
    }
    return {
      openStreetpart: (id) => {
        const request = {
          type      : `STREETPART_OPEN`,
          [CALL_API]: {
            url: `${ifDayShift ? URL_DAY : URL_NIGHT}/${id}/start`,
            options: {
              method: `PUT`,
            }
          },
        }
        sendRequest(request)
      },
      closeStreetpart: (id) => {
        const request = {
          type      : `STREETPART_CLOSE`,
          [CALL_API]: {
            url: `${ifDayShift ? URL_DAY : URL_NIGHT}/batch`,
            query: {
              query: `id==${id}`,
            },
            options: {
              method: `PUT`,
              body: {
                complete: true,
              },
            }
          },
        }
        sendRequest(request)
      },
      reopenStreetpart: (id) => {
        const request = {
          type      : `STREETPART_REOPEN`,
          [CALL_API]: {
            url: `${ifDayShift ? URL_DAY : URL_NIGHT}/batch`,
            query: {
              query: `id==${id}`,
            },
            options: {
              method: `PUT`,
              body: {
                incomplete: true,
              },
            }
          },
        }
        sendRequest(request)
      },
    }
  }),
)

const StreetParts = ({ streetParts, ifDayShift, openStreetpart, closeStreetpart, reopenStreetpart }) => {
  return <div className="streetpart-widget-table"><WidgetTable
    data={streetParts}
    columns={columns}
    ifDayShift={ifDayShift}
    openStreetpart={openStreetpart}
    closeStreetpart={closeStreetpart}
    reopenStreetpart={reopenStreetpart}
  /></div>
}

export default enhancer(StreetParts)