import React from 'react'
import { Link } from 'react-router-dom'
import {
    compose,
    withPropsOnChange,
    branch,
    renderComponent,
} from 'recompose'
import isEmpty from 'lodash/isEmpty'
import { ApiTransport, omitApiTransportProps } from '@infotech/lib-api-middleware'
import { WidgetTable } from '@infotech/base-components'
import { fieldApps } from '@infotech/view-config'

import {
  expressInspectionsApiActions,
  EXPRESS_INSPECTIONS_SCHEMA_NAME,
  expressInspectionsSelector,
} from 'api/expressInspections'
import {
  expressInspectionsDayApiActions,
    EXPRESS_INSPECTIONS_DAY_SCHEMA_NAME,
    expressInspectionsDaySelector,
} from 'api/expressInspectionsDay'
import { CRUD_PATH as CRUD_PATH_NIGHT } from 'modules/ExpressInspectionsModule/constants'
import { CRUD_PATH as CRUD_PATH_DAY } from 'modules/ExpressInspectionsDayModule/constants'
import { fieldsConf } from 'modules/ExpressInspectionsModule/fieldsConf'

const columns = [ {
    HeaderView    : () => `Id`,
    width         : `70px`,
    ColumnView    : ({ rowData: { id }, ifDayShift }) => <div><Link to={`/${ifDayShift ? CRUD_PATH_DAY : CRUD_PATH_NIGHT}/read/${id}`}>
        {id}
    </Link></div>,
  }, {
    HeaderView    : () => `Дата создания`,
    width         : `130px`,
    ColumnView    : ({ rowData: { createDate } }) => {
      const Formatter = fieldsConf.createDate.apps[fieldApps.COLUMN].formatter
      return <div><Formatter value={createDate} /></div>
    },
  }, {
    HeaderView    : () => `Создатель`,
    width         : `240px`,
    ColumnView    : ({ rowData: { creator } }) => {
      const Formatter = fieldsConf.creator.apps[fieldApps.COLUMN].formatter
      return <div><Formatter value={creator} /></div>
    },
  }, {
    HeaderView    : () => `Инспектор`,
    width         : `240px`,
    ColumnView    : ({ rowData: { inspector } }) => {
      const Formatter = fieldsConf.inspector.apps[fieldApps.COLUMN].formatter
      return <div><Formatter value={inspector} /></div>
    },
  },  {
    HeaderView    : () => `Дефект`,
    width         : `90px`,
    ColumnView    : ({ rowData: { defectNumber } }) => {
      const Formatter = fieldsConf.defectNumber.apps[fieldApps.COLUMN].formatter
      return <div><Formatter value={defectNumber} /></div>
    },
  }, {
    HeaderView    : () => `Дата выполнения`,
    width         : `150px`,
    ColumnView    : ({ rowData: { factDate } }) => {
      const Formatter = fieldsConf.factDate.apps[fieldApps.COLUMN].formatter
      return <div><Formatter value={factDate} /></div>
    },
  }, {
    HeaderView    : () => `Статус дефекта`,
    width         : `200px`,
    ColumnView    : ({ rowData: { defectStatus } }) => {
      return <div>{defectStatus}</div>
    },
  }, {
    HeaderView    : () => `Инв. №`,
    width         : `90px`,
    ColumnView    : ({ rowData: { streetNumber } }) => {
      const Formatter = fieldsConf.streetNumber.apps[fieldApps.COLUMN].formatter
      return <div><Formatter value={streetNumber} /></div>
    },
  }, {
    HeaderView    : () => `Наименование`,
    width         : `200px`,
    ColumnView    : ({ rowData: { streetName } }) => {
      return <div>{streetName}</div>
    },
  }, {
    HeaderView    : () => `Объект`,
    width         : `90px`,
    ColumnView    : ({ rowData: { loNumber } }) => {
      const Formatter = fieldsConf.loNumber.apps[fieldApps.COLUMN].formatter
      return <div><Formatter value={loNumber} /></div>
    },
  },
]

const NoData = () => <div className="streetmap-nodefects">Нет данных.</div>

const enhancer = compose(
  branch(
    ({ data }) => isEmpty(data?.expressInspections),
    renderComponent(NoData)
  ),
  withPropsOnChange(
      [`data`],
      ({ data: { type: { key } } }) => ({
          ifDayShift: (key === `sg_shift_day`)
      })
  ),
  withPropsOnChange(
      [`data`],
      ({ data: { expressInspections }, ifDayShift, }) => ({
        entity      : ifDayShift ? EXPRESS_INSPECTIONS_DAY_SCHEMA_NAME : EXPRESS_INSPECTIONS_SCHEMA_NAME,
        apiAction   : ifDayShift ? expressInspectionsDayApiActions.fetch : expressInspectionsApiActions.fetch,
        dataSelector: ifDayShift ? expressInspectionsDaySelector : expressInspectionsSelector,
        query       : {
          query: `id=in=(${expressInspections.join(`,`)})`,
          limit: `-1`,
        },
        dataPropName: `expressInspections`,
      })
    ),
  ApiTransport,
  omitApiTransportProps,
)

const ExpressInspections = ({ expressInspections, ifDayShift,  }) => {
  return <div className=""><WidgetTable
    data={expressInspections}
    columns={columns}
    ifDayShift={ifDayShift}
  /></div>
}

export default enhancer(ExpressInspections)