import {
  defaultProps, compose,
} from 'recompose'

import { FeatureChecker } from '@infotech/featureList'
import {
  defaulCrudConfigMaker,
  CRUDRouteMaker,
  ListModalEditButton,
  exportToFileAsyncConfig,
  defaultReadToolbarComponents,
} from '@infotech/crud'

import { URL } from 'api/streetParts'
import {
  getColumnsConf,
  getFormConfig,
  defaultFormList,
  getViewConfig,
  defaultViewList,
  defaultFormListEdit,
  defaultFilterList,
  getFilterConfig,
  getAllColumns,
} from './fieldsConf'
import { CRUD_PATH } from './constants'
import MassChange from './components/MassChange'
import Map from './components/Map'
import Defects from './components/Defects'
import Print from './components/Print'
import History, { HISTORY_TAB_ID, HISTORY_TAB_TITLE } from 'widgets/History'

export const editEnhancer = compose(
  defaultProps({
    featureToCheck: `sg.general-edit.access`,
  }),
  FeatureChecker,
)

export const defaultModuleConfig = {
  getGridConfig     : getColumnsConf,
  getFormConfig,
  getViewConfig,
  defaultFormList,
  defaultViewList,
  defaultFormListEdit,
  shouldRequestCount: false,
  routing           : {
    list: {
      title : `Плановые участки (ночные)`,
      manageColumns: true,
      useQuickView: true,
      getAllColumns,
      toolbarButtons: [{
        id       : `editStreetPart`,
        component: editEnhancer(ListModalEditButton),
      }],
      filter: {
        defaultFilterList,
        getFilterConfig,
      },
      toolbarRightSideComponents: [{
        component: MassChange,
        componentConfig: {
          getFilter: exportToFileAsyncConfig.getFilter,
        }
      }],
      exportToFileAsyncConfig: {
        ...exportToFileAsyncConfig,
        exportTypes: {
          xlsx: `XLSX`,
          xls : `XLS`,
        },
        fixedEntity: `sg-night-planned-street-part`,
        fixedFields: [
          "id", "inventoryNumber", "status", "overdue", 
          "objectClass", "streetName", "landmark","periodicity","inspector",
          "plannedDate", "limitDate", "startDate", "factDate", "electricalNetwork",
          "district", "borough", "tags", "category", "ahpCategory",
          "outboundMagistral", "route", "lightingObjectCount", "streetPartType",
          "streetPartSubtype",
        ],
      },
    },
    edit: {
      title           : `Редактирование участка`,
      submitButtonText: `Сохранить`,
      successMessage  : `Участок отредактирован`,
    },
    read: {
      toolbarComponents: [
        {
          id: defaultReadToolbarComponents[0].id,
          component: editEnhancer(defaultReadToolbarComponents[0].component),
        },
        {
          id: `print`,
          component: Print,
        }
      ],
      rightWidgets : [ Map ],
      bottomWidgets: [
        {
          tabId    : `DEFECTS_TAB`,
          component: Defects,
          title    : `Дефекты`,
        },
        {
          tabId    : HISTORY_TAB_ID,
          component: History,
          title    : HISTORY_TAB_TITLE,
          hide     : (featureList) => !featureList[`issue.history.read`],
        },
      ],
    },
  },
}

export const moduleCrudConfig = (moduleConfig = defaultModuleConfig, crudPath = CRUD_PATH, apiUrl = URL) => (
  defaulCrudConfigMaker(moduleConfig, crudPath, apiUrl)
)

export const CRUDRoute = (config = moduleCrudConfig(), path = CRUD_PATH) => CRUDRouteMaker(config, path)
