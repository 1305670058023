import {
  defaulCrudConfigMaker,
  CRUDRouteMaker,
  ListModalEditButton,
  defaultReadToolbarComponents,
} from '@infotech/crud'

import { URL } from 'api/expressInspections'
import {
  getColumnsConf,
  getFormConfig,
  defaultFormList,
  getViewConfig,
  defaultViewList,
  defaultFormListEdit,
  defaultFilterList,
  getFilterConfig,
  getAllColumns,
} from './fieldsConf'
import { CRUD_PATH } from './constants'
import History, { HISTORY_TAB_ID, HISTORY_TAB_TITLE } from 'widgets/History'
import { editEnhancer } from '../StreetPartModule/CRUDConfig'

export const defaultModuleConfig = {
  getGridConfig     : getColumnsConf,
  getFormConfig,
  getViewConfig,
  defaultFormList,
  defaultViewList,
  defaultFormListEdit,
  shouldRequestCount: false,
  routing           : {
    list: {
      title : `Внеплановые осмотры (ночные)`,
      manageColumns: true,
      getAllColumns,
      toolbarButtons: [{
        id       : `editExpressInspections`,
        component: editEnhancer(ListModalEditButton),
      }],
      filter: {
        defaultFilterList,
        getFilterConfig,
      },
    },
    edit: {
      title           : `Редактирование внепланового осмотра`,
      submitButtonText: `Сохранить`,
      successMessage  : `Успешно`,
    },
    read: {
      toolbarComponents: [
        {
          id: defaultReadToolbarComponents[0].id,
          component: editEnhancer(defaultReadToolbarComponents[0].component),
        },
      ],
      bottomWidgets: [        
        {
          tabId    : HISTORY_TAB_ID,
          component: History,
          title    : HISTORY_TAB_TITLE,
          hide     : (featureList) => !featureList[`issue.history.read`],
        },        
      ],
    },
  },
}

export const moduleCrudConfig = (moduleConfig = defaultModuleConfig, crudPath = CRUD_PATH, apiUrl = URL) => (
  defaulCrudConfigMaker(moduleConfig, crudPath, apiUrl)
)

export const CRUDRoute = (config = moduleCrudConfig(), path = CRUD_PATH) => CRUDRouteMaker(config, path)
