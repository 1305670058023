import React from 'react'
import { Helmet } from 'react-helmet'

import { CORE_URL, REACT_APP_DOCUMENT_LOGO } from 'api/constants'
import defaultDocumentLogo from './favicon.ico'

const getDocumentLogo = () => (
  REACT_APP_DOCUMENT_LOGO && REACT_APP_DOCUMENT_LOGO !== `undefined`
    ? `${CORE_URL.replace(`/api`, ``)}/static/logo/document-icon.png`
    : defaultDocumentLogo
)

const DocumentLogo = () => (
  <Helmet>
    <link rel="shortcut icon" href={getDocumentLogo()} />
  </Helmet>
)

export default DocumentLogo
